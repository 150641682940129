import { createGlobalStyle } from 'styled-components';
import { connect } from 'react-redux';
import getGoogleFontURL from '../utils/getGoogleFontURL';

/**
 * Returns a JS style object with basic typography for html, body, heading, and paragraph tags.
 *
 * Expects a theme object with a `typorgraphy` property, which is an object with `h1` through `h6` and a `body2`.
 *
 * @param {object} theme
 */
const getTypographyStyles = ({ typography }) => {
  const styles = {
    p: typography.body2,

    'html, body': {
      fontFamily: typography.body2.fontFamily,
      fontSize: typography.body2.fontSize
    }
  };

  ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].forEach(
    h => (styles[h] = typography[h])
  );

  return styles;
};

const GlobalTypography = createGlobalStyle`
  /* import google fonts */
  ${({ themes }) => `@import url(${getGoogleFontURL(themes)});`}

  /* apply the styles from the light theme globally */
  ${({ light }) => getTypographyStyles(light)}
`;

export default connect(state => ({
  themes: state.content.themes,
  light: state.content.themes.light
}))(GlobalTypography);
