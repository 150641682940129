const pagesThatDontRequireLogin = [
  'email_sent',
  'first_time',
  'home',
  'login',
  'dual-login',
  'need_help',
  'reset_email_sent',
  'samlerror',
  'set_password',
  'test_page',
  'report-builder',
  'no_tenant',
  'dsconnected',
  'dssigned'
];

export default pagesThatDontRequireLogin;
