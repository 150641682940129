export const SET_AUTOSAVE_STATUS = 'state/autosave/SET_AUTOSAVE_STATUS';

const autosave = (state = {}, { type, id, status }) => {
  if (!id || !status) {
    return state;
  }

  const newStatus = { status, timestamp: Date.now() };

  switch (type) {
    case SET_AUTOSAVE_STATUS:
      return {
        ...state,
        [id]: newStatus
      };

    default:
      return state;
  }
};

export default autosave;