import { connect } from 'react-redux';
import format from '../utils/format';

/**
 * Gets course title from `config.yml` via Redux, applies i18n, and sets the document title
 */
const SetDocumentTitle = ({ title, translations, locale }) => {
  if (title) {
    document.title = format(translations, locale, title);
  }

  return null;
};

const mapStateToProps = state => ({
  title: state.content.config.title,
  translations: state.content.translations,
  locale: state.state.locale
});

export default connect(mapStateToProps)(SetDocumentTitle);
