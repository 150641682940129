import IntlMessageFormat from 'intl-messageformat';

const format = (msgs, locale, key, values = {}) => {
  const msg = msgs[locale][key];

  return msg
    ? new IntlMessageFormat(msg, locale).format(values)
    : process.env.REACT_APP_SHOW_KEYS
    ? key
    : '';
};

export default format;