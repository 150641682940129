const SET_SORT = 'gridSort/SET_SORT';

// reducer as default export
const gridSort = (state = {}, action) => {
  switch (action.type) {
    case SET_SORT:
      return action.payload;
    default:
      return state;
  }
};

export default gridSort;

// action creators
export const setSort = id => ({
  type: SET_SORT,
  payload: id
});
