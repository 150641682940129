import fetch from 'cross-fetch';
import jwt from 'jsonwebtoken';
import { push } from 'connected-react-router';
import {
  openBanner,
  closeBanner,
  submissionStarted,
  submissionComplete
} from '../actionCreators';
import Cookies from 'universal-cookie';
import getTenant from '../../utils/getTenant';
import queryString from 'query-string';

const cookies = new Cookies();

const sleep = milliseconds => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

const login = (state = null, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      // start a redirect
      return state;
    case LOGIN_FAILURE:
      return action.username;
    default:
      return state;
  }
};

export default login;

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
function loginRequest(username, password) {
  submissionStarted('login');
  return {
    type: LOGIN_REQUEST,
    username,
    password
  };
}

function loginSuccess(username) {
  submissionComplete('login');
  return {
    type: LOGIN_SUCCESS,
    username,
    receivedAt: Date.now()
  };
}

export const LOGIN_FAILURE = 'LOGIN_FAILURE';
function loginFailure(username, e) {
  submissionComplete('login');
  return {
    type: LOGIN_FAILURE,
    username,
    data: e,
    receivedAt: Date.now()
  };
}

export function callLogin(values, url, method = 'POST', sleepTime = 0) {
  closeBanner('message401');
  closeBanner('message500');
  const { username, password } = values;
  return dispatch => {
    dispatch(loginRequest(username, password));
    var fetchParams = {
      method: method,
      headers: {
        Authentication: 'aaa',
        Authorization: 'aaa',
        'x-tenant-id': getTenant(),
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: 0
      },
      cache: 'no-cache'
    };
    var queryParams = { userName: username, password: password };
    if (method === 'POST') {
      fetchParams.body = JSON.stringify(queryParams);
    } else {
      function objToQueryString(obj) {
        const keyValuePairs = [];
        for (let i = 0; i < Object.keys(obj).length; i += 1) {
          keyValuePairs.push(
            `${encodeURIComponent(Object.keys(obj)[i])}=${encodeURIComponent(
              Object.values(obj)[i]
            )}`
          );
        }
        return keyValuePairs.join('&');
      }
      url += '?' + objToQueryString(queryParams);
    }
    let resp;
    return fetch(url, fetchParams)
      .then(response => {
        return new Promise(function (resolve) {
          sleep(sleepTime).then(() => resolve(response));
        });
      })
      .then(response => {
        var auth, redirect, jwtToken, decoded;
        redirect = queryString.parse(window.location.search).redirect || '';
        auth = response.headers.get('Authorization');
        resp = response;
        if (response.status !== 200) {
          throw new Error(response);
        }
        cookies.set(`auth-header-${getTenant()}`, auth, { path: '/' });
        dispatch(loginSuccess(username));
        if (redirect) {
          dispatch(push(redirect));
        } else {
          jwtToken = auth.replace(/(Bearer: )/, '');
          if (jwtToken) {
            decoded = jwt.decode(jwtToken);
            if (
              decoded &&
              decoded.roles &&
              decoded.roles.length &&
              decoded.roles[0].role
            ) {
              dispatch(push('/forms'));
              return;
            }
          }
          dispatch(push(values.targetURL));
        }
      })
      .catch(e => {
        if (!resp || resp.status >= 500) {
          if (values.ErrorURL500) {
            dispatch(loginFailure(username, e));
            dispatch(push(values.ErrorURL500));
          } else {
            openBanner('message500');
            dispatch(loginFailure(username, e));
          }
        } else if (resp.status === 401) {
          openBanner('message401');
          dispatch(loginFailure(username, e));
        }
      });
  };
}
