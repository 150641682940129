import React from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
  padding-top: 35vh;
  margin: auto;
  text-align: center;
`;

const IeEndOfLifeMsg = () => {
  return (
    <Container>
      <h2>Browser not supported by Omnitrack</h2>
      <em>
        Internet Explorer is no longer supported by Omnitrack. <br />
        Please try accessing the system in Google Chrome or a different browser.
      </em>
    </Container>
  );
};

export default IeEndOfLifeMsg;
