const SET_ACTIVETAB = 'userManagementTabs/SET_ACTIVETAB';

// reducer as default export
const userManagementTabs = (state = null, action) => {
  switch (action.type) {
    case SET_ACTIVETAB:
      return action.payload;
    default:
      return state;
  }
};

export default userManagementTabs;

// action creators
export const setUserManagementTabs = id => ({
  type: SET_ACTIVETAB,
  payload: id
});
