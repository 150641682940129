const SET_VALUE = 'fieldChanged/SET_VALUE';

// reducer as default export
const fieldChanged = (state = false, action) => {
  switch (action.type) {
    case SET_VALUE:
      return action.payload;
    default:
      return state;
  }
};

export default fieldChanged;

// action creators
export const setValue = id => ({
  type: SET_VALUE,
  payload: id
});
