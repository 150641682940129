import React from 'react';
import { preloadLoadables } from '../utils/components';
import pages from './default/pages.json';
import { nonAdminPages } from './nonAdminPages';

let allPages = pages ;

if (!allPages) {
  allPages = [
    {
      id: 'no_content',
      type: 'SimplePage',
      children: [
        {
          type: 'Center',
          children: [
            {
              type: 'Paper',
              style: {
                maxWidth: '30em',
                position: 'relative',
                margin: '0 auto',
                backgroundColor: '#444'
              },
              children: [
                <span style={{ color: '#ddd' }}>Ready to start building!</span>,
                <br />,
                <br />,
                <span style={{ color: '#ddd' }}>Please write some YAML.</span>
              ]
            }
          ]
        }
      ]
    }
  ];
}

// TODO: document this
const unionByProp = (arr1, arr2, prop) => {
  const seenProps = arr1.map(item => item[prop]);
  const result = arr1.slice(0);

  arr2.forEach(item => {
    if (!seenProps.includes(item[prop])) {
      seenProps.push(item[prop]);
      result.push(item);
    }
  });

  return result;
};

if (process.env.REACT_APP_FIRM) {
  allPages = unionByProp(allPages, 'id');
}

allPages.filter(page => nonAdminPages.includes(page.id)).map(preloadLoadables);

export default allPages;
