import apm from './rum';
import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './store';
import App from './App';
import './utils/applyJWT';
import getJWT from './utils/getJWT';
import getEnv from './utils/getEnv';
//import apm from './rum';
import './hubspot';
import Cookies from 'universal-cookie';
import queryString from 'query-string';
import SimpleScrollbar from './utils/simple-scrollbar';
import writeLog from './utils/log';

window.SimpleScrollbar = SimpleScrollbar;

writeLog('Index.js REACT_APP_API_BASE: ' + process.env.REACT_APP_API_BASE);
writeLog('Index.js REACT_APP_S3_ENV: ' + process.env.REACT_APP_S3_ENV);

const cookies = new Cookies();
try {
  apm.setInitialPageLoadName('Omnitrack Main Page');
} catch (err) {
  writeLog('Could not set setInitialPageLoadName');
}

ReactDOM.render(<App />, document.getElementById('root'));

// // HMR in development
// if (process.env.NODE_ENV !== 'production') {
//   if (module.hot) {
//     module.hot.accept('./App', () => {
//       console.log(
//         '%c🔥🔥🔥  REACT HOT RELOAD ',
//         'background:#007; color:#fe0; padding: 3px 1ch; border-radius: 2px;'
//       );
//       ReactDOM.render(<App />, document.getElementById('root'));
//     });
//   }
// }

// add firm name to body class for firm styles
const firm = process.env.REACT_APP_FIRM;

if (firm) {
  document.body.classList.add(`--firm-${firm}`);
}

const testIdSelector =
  'a[data-testid], button[data-testid], input[data-testid], textarea[data-testid]';

window.addEventListener('load', e => {
  if (queryString.parse(window.location.search).saml) {
    cookies.set('is-saml', '1', { path: '/' });
  } else {
    cookies.set('is-saml', '', { path: '/' });
  }
  SimpleScrollbar.initAll();
});

window.addEventListener('click', e => {
  try {
    writeLog('>>Sending buttonClick apm transaction');
    apm.startTransaction('buttonClick', 'userInput');
  } catch (err) {
    writeLog('>>Could not start apm transaction');
  }

  var sessionJWT = getJWT();
  var applicationEnv = getEnv();

  //var applicationBaseURL = (window.location.href.indexOf('.com') !== -1) ? (window.location.href.split('.com')[0] + ".com") : (window.location.href);

  var applicationBaseURL = window.location.origin;

  try {
    writeLog(apm.getCurrentTransaction());
    apm.addLabels({ application: applicationBaseURL });
    apm.addLabels({ appEnvironment: applicationEnv });
    apm.addLabels({ tenantID: sessionJWT.tenantid });
    apm.addLabels({ sessionID: sessionJWT.userId + '_' + sessionJWT.exp });
    writeLog('Added tenant ID label: ' + sessionJWT.tenantid);
    writeLog(
      'Added sessionID label: ' + sessionJWT.userId + '_' + sessionJWT.exp
    );
  } catch (err) {
    apm.addLabels({ sessionID: 'noSession' });
  }

  try {
    writeLog(
      '>>Tagging this transaction with target:' +
        e.target.closest(testIdSelector).getAttribute('data-testid')
    );
    apm.addLabels({
      target: e.target.closest(testIdSelector).getAttribute('data-testid')
    });
  } catch (err) {
    writeLog('>>Tagging this transaction with target:noElement');
    apm.addLabels({ target: 'noElement' });
  }

  try {
    writeLog(
      ">>Adding a span to this transaction type 'input', action 'click'"
    );
    var thisSpan = apm.startSpan(
      'input_' + apm.getCurrentTransaction().id,
      'input'
    );
    thisSpan.action = 'click';
    thisSpan.end();
  } catch (err) {
    writeLog('>>Could not add span to this transaction');
    writeLog(err);
  }

  try {
    var currentTransaction = apm.getCurrentTransaction();
    if (apm.getCurrentTransaction()) {
      currentTransaction.end();
      writeLog('>>Transaction ' + currentTransaction.id + ' ended');
    }
  } catch (err) {
    writeLog('>>Could not get or end current apm transaction:');
    writeLog(err);
  }
});
