import { useState } from 'react';

const GetMobileNavAPI = () => {
  const [title, setTitle] = useState('');
  const [useBackBtn, setUseBackBtn] = useState(false);

  const api = {
    title,
    setTitle,
    useBackBtn,
    setUseBackBtn
  };

  return api;
};

export default GetMobileNavAPI;
