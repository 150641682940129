import React from 'react';
import { Collapse, Fade, Grow, Slide, Zoom } from '@material-ui/core';

const animationsDictionary = {
  fade: Fade,
  collapse: Collapse,
  grow: Grow,
  slide: Slide,
  zoom: Zoom
};

const Animation = ({
  type = 'fade',
  playAnimation = true,
  animationOnMount = true,
  children,
  ...props
}) => {
  let safeType = animationsDictionary[type] ? type : 'fade'; // default animation = fade
  const AnimationComponent = animationsDictionary[safeType];

  return (
    <AnimationComponent in={playAnimation} appear={animationOnMount} {...props}>
      <div data-id="animation-container"> {children}</div>
    </AnimationComponent>
  );
};

export default Animation;
