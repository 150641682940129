import { init as initApm } from '@elastic/apm-rum';
var apm = initApm({
 // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
 serviceName: 'omnitrack-ui',
 // Set the version of your application
 // Used on the APM Server to find the right sourcemap
 serviceVersion: '1.0',
 // Set custom APM Server URL (default: http://localhost:8200)
// serverUrl: 'https://course-monitoring.vinciworks-dev.com:7443',
serverUrl: 'https://monitoring.vinciworks-dev.com:8200', 
// distributedTracingOrigins: ['http://localhost:8080'],
});
export default apm;