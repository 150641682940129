import fetchAPI from './fetchAPI';

export const LICENSE_TYPE = {
  CLIENT_NAME: 'client_name',
  SYS_FROM_EMAIL_ADDRESS: 'from_email',
  S3_LOGO_PATH: 'client_logo_path',
  SSO: 'SSO',
  EXTERNAL_USERS: 'externalusers',
  MAX_FORMS: 'maxForms',
  MAX_SUBMISSIONS_MONTH: 'maxSubmissionsMonth',
  MAX_USERS: 'maxUsers',
  MAX_APIS_PER_MONTH: 'maxAPICallsMonth',
  DOCUSIGN: 'docusign',
  VINCI_ACCESS: 'vinci_support_access',
  OMNITRACK_LITE_PRODUCT: 'omnitrackLiteProduct'
};

/**
 * This functions returns a promise
 * @param {*} licenseType
 */
const getLicense = licenseType => {
  const apiEndpoint = '/configurations/v1/modules/license';
  const specificLicenseQuery = licenseType
    ? typeof licenseType === 'string'
      ? `,{"field":"'code'","op":"=","value":"${licenseType}"}`
      : licenseType
          .map(l => `,{"field":"'code'","op":"=","value":"${l}"}`)
          .join('')
    : '';
  const query = `[[{"field":"'module'","op":"=","value":"provisioning"},{"field":"'configName'","op":"=","value":"license"}${specificLicenseQuery}]]`;

  return fetchAPI(`${apiEndpoint}?order=id&query=${query}`).then(response => {
    const configs = response.configs;
    if (licenseType) {
      const license = configs.find(c => c.code === licenseType);
      return license || {};
    }
    return configs;
  });
};

export default getLicense;
