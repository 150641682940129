// import { BrowserRouter } from ;
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import config from '../../data/config';
import getTenant from '../../utils/getTenant';
import Cookies from 'universal-cookie';
import store from '../../store';
import { push } from 'connected-react-router';

const usingBrowserHistory = config.router === 'browser';

let createChosenHistory = usingBrowserHistory
  ? require('history').createBrowserHistory
  : require('history').createHashHistory;

const cookies = new Cookies();

var tenant = getTenant();
var cookieTenant = cookies.get('tenant');
var pathPiece = window.location.pathname.split('/')[1];

if (!tenant) {
  pathPiece = pathPiece.toLowerCase();
  if (pathPiece !== 'home' && pathPiece !== 'dsconnected' && pathPiece !== 'dssigned') {
    setTimeout(function () {
      cookies.remove(`auth-header-${getTenant()}`, { path: '/' });
      cookies.set('is-saml', '', { path: '/' });
      store.dispatch(push('/no_tenant'));
    }, 1000);
  }
} else if (!cookieTenant) {
  cookies.set('tenant', tenant, { path: '/' });
} else if (cookieTenant !== tenant) {
  cookies.set('tenant', tenant, { path: '/' });
  cookies.remove(`auth-header-${cookieTenant}`, { path: '/' });
  cookies.remove(`auth-header-${getTenant()}`, { path: '/' });
}

let history = createChosenHistory({ basename: '/' + tenant });

if (process.env.NODE_ENV === 'test') {
  history = require('history').createMemoryHistory({
    initialEntries: ['/welcome']
  });
}

export default history;

// TODO organize files
export const Router = ({ children }) => (
  <ConnectedRouter history={history}>{children}</ConnectedRouter>
);
