import React, { forwardRef } from 'react';
import MuiButton from '@material-ui/core/Button';
import debounce from 'lodash.debounce';
import styled from 'styled-components/macro';
import { CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';

const StyledButton = styled(MuiButton)`
  && {
    .uigButton__pending {
      position: absolute;
      left: 50%;
      height: 1.5em;
      width: 1.5em;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: ${({ pending }) => (pending ? 1 : 0)};
      visibility: ${({ pending }) => (pending ? 'visible' : 'hidden')};
      transition-delay: ${({ pending }) => (pending ? '200ms' : '0s')};

      svg {
        color: ${({ variant, theme }) =>
          variant === 'contained' ? '#fff' : theme.palette.primary.main};
      }
    }

    .uigButton__children {
      justify-content: center;
      min-width: 5em;
      text-transform: none !important;
      display: inline-flex;
      /* aligns icon */
      align-items: center;
      opacity: ${({ pending }) => (pending ? 0.2 : 1)};
      transition-delay: ${({ pending }) => (pending ? '200ms' : '0s')};
    }
  }
  &:not(:first-child) {
    ${({ $disableGutters }) => !$disableGutters && 'margin-left: 8px;'}
    ${({ $reverseGutters }) => $reverseGutters && 'margin: 0 8px 0 0;'}
  }

  ${({ $customCSS }) => $customCSS}
`;

const NativeDisabledButton = React.forwardRef(({ pending, ...props }, ref) => (
  <button {...props} ref={ref} disabled />
));

const MyLink = React.forwardRef((props, ref) => (
  <Link
    innerRef={ref}
    to={props.targetURL ? props.targetURL : props.to}
    {...props}
  />
));

const Button = forwardRef(
  (
    {
      onClick = () => {},
      wait = 500,
      children,
      pending = false,
      variant = 'contained',
      leading = true,
      customCSS,
      ...rest
    },
    ref
  ) => {
    const debouncedOnClick = React.useCallback(
      debounce(onClick, wait, { leading, trailing: false }),
      [onClick, wait, leading]
    );

    let component;

    if (pending) {
      component = NativeDisabledButton;
    } else if (rest.to) {
      component = MyLink;
    } else {
      component = 'button';
    }

    return (
      <StyledButton
        onClick={debouncedOnClick}
        color="primary"
        variant={variant}
        pending={pending || null}
        // disable the native button while pending
        component={component}
        $customCSS={customCSS}
        {...rest}
        ref={ref}
      >
        <span className="uigButton__children">{children}</span>
        <div className="uigButton__pending">
          {pending && <CircularProgress size="1.5em" thickness={6} />}
        </div>
      </StyledButton>
    );
  }
);

export default Button;
