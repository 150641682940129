const SET_USERPROFILE = 'userProfile/SET_USERPROFILE';

// reducer as default export
const userProfile = (state = null, action) => {
  switch (action.type) {
    case SET_USERPROFILE:
      return action.payload;
    default:
      return state;
  }
};

export default userProfile;

// action creators
export const setUserProfile = id => ({
  type: SET_USERPROFILE,
  payload: id
});
