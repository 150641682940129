const Colors = {
  PRIMARY: '#4c84ff',
  SECONDARY: '#6DFACD',
  OT_RED: '#f50057',
  FLAG_RED: '#f94040',
  BACKGROUND_GREY: '#f7f8fc',
  ICON_GREY: '#757575',
  OT_GREY: '#e0e0e0',
  DARK_GREY: '#383838',
  BORDER_GREY: '#c4c4c4',
  LINKS: '#598dff'
};

export default Colors;
