import React from 'react';

import {
  Button as MuiButton,
  Menu,
  MenuItem as MuiMenuItem,
  Zoom,
  Fade
} from '@material-ui/core';
import Language from '@material-ui/icons/Language';
import Check from '@material-ui/icons/Check';
import { connect } from 'react-redux';
import { setLocale } from '../store/modules/state/locale';
import styled from 'styled-components/macro';
import FillParent from '../components/layouts/FillParent';

const Widget = styled.span`
  z-index: 5;
  position: fixed;
  bottom: 0;
  left: 100px;

  @media print {
    display: none;
  }
`;

const LanguageIcon = styled(Language)`
  && {
    font-size: 16px;
    margin-right: 0.75ch;
  }
`;

const MenuItem = styled(MuiMenuItem)`
  && {
    position: relative;
    z-index: 0;
    display: flex;
    justify-content: stretch;
  }
`;

const MenuItemBackground = styled(FillParent)`
  z-index: -1;
  background-color: ${({ theme }) => theme.palette.primary.light};
  /* opacity: 0.5; */
`;

const CheckIcon = styled(Check)`
  && {
    font-size: 16px;
    margin-left: 0.75ch;
  }
`;

const Button = styled(MuiButton)`
  && {
    border-radius: ${({ theme }) =>
      `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px 0px`};
    text-transform: none;
    background-color: ${({ theme }) => `${theme.palette.pale_grey}`};
  }
`;

class LocaleWidget extends React.Component {
  state = {
    anchorEl: null
  };

  handleOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleSelection = key => {
    this.props.dispatch(setLocale(key));
    // this.handleClose();
  };

  render() {
    const { anchorEl } = this.state;
    const { locale, locales } = this.props;

    // return early if we don't have 2 or more languages
    if (Object.keys(locales).length < 2) {
      return null;
    }

    if (Object.keys(locales).length < 2) {
      return null;
    }

    return (
      <Widget>
        <Button onClick={this.handleOpen} size="small">
          <LanguageIcon />

          <span style={{ fontSize: 12 }}>{locales[locale]}</span>
        </Button>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          {Object.keys(locales).map(value => (
            <MenuItem
              onClick={this.handleSelection.bind(null, value)}
              value={value}
              locale={locale}
              key={value}
            >
              <Fade in={value === locale}>
                <MenuItemBackground />
              </Fade>
              <span
                style={{
                  fontSize: 12,
                  flex: 'none',
                  opacity: 0.8,
                  padding: '0 1ch'
                }}
              >
                {locales[value]}
              </span>
              {
                <Zoom in={value === locale} timeout={250}>
                  <CheckIcon />
                </Zoom>
              }
            </MenuItem>
          ))}
        </Menu>
      </Widget>
    );
  }
}

const mapStoreToProps = store => ({
  locale: store.state.locale,
  locales: store.content.locales
});

export default connect(mapStoreToProps)(LocaleWidget);
