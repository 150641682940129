import React from 'react';
import CheckboxIcon from '@material-ui/icons/CheckBox';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircleOutlined';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import EmailIcon from '@material-ui/icons/Email';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import InfoIcon from '@material-ui/icons/Info';
import LinkIcon from '@material-ui/icons/Link';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import Image from '../../components/Image';
// import PersonIcon from '@material-ui/icons/AccountCircle';
import styled from 'styled-components/macro';

// const ButtonsFieldWrapper = styled.div`
//   padding: 0.5rem 0.4rem 0;
// `;

// const ButtonsIcon = () => (
//   <ButtonsFieldWrapper>
//     <Image src="buttons-field-type.svg" />
//   </ButtonsFieldWrapper>
// );

export const fieldTypes = {
  text: {
    name: 'forms.field_type.text',
    type: 'text',
    icon: 'Abc',
    color: '#0abab4',
    component: 'Fields.Text'
  },
  long_text: {
    name: 'forms.field_type.long_text',
    type: 'text',
    icon: '¶',
    color: '#feb44e',
    component: 'Fields.Text'
  },
  link: {
    name: 'forms.field_type.link',
    type: 'text',
    icon: <LinkIcon />,
    color: '#598dff',
    component: 'Fields.Link'
  },
  number: {
    name: 'forms.field_type.number',
    type: 'number',
    icon: '123',
    color: '#aad368',
    component: 'Fields.Number'
  },
  score: {
    name: 'forms.field_type.score',
    type: 'number',
    icon: '+ -',
    color: '#aad368',
    component: 'Fields.Number'
  },
  dropdown: {
    name: 'forms.field_type.dropdown',
    type: 'enum',
    options: true,
    icon: <ArrowDropDownCircleIcon />,
    color: '#fb5f7e',
    component: 'Fields.Dropdown'
  },
  multiple_choice: {
    name: 'forms.field_type.multiple_choice',
    type: 'text',
    icon: <CheckboxIcon />,
    options: true,
    color: '#d048c7',
    component: 'Fields.MultipleChoice'
  },
  status: {
    name: 'forms.field_type.multiple_choice',
    type: 'text',
    icon: <CheckboxIcon />,
    options: true,
    color: '#d048c7',
    component: 'Fields.MultipleChoice',
    dontList: true
  },
  yes_no: {
    name: 'forms.field_type.yes_no',
    type: 'text',
    icon: <RadioButtonCheckedIcon />,
    options: ['Yes', 'No'],
    color: '#4886ff',
    component: 'Fields.YesNo'
  },
  confirmation: {
    name: 'forms.field_type.confirmation',
    type: 'text',
    icon: <VerifiedUserIcon />,
    options: true,
    color: '#aad368',
    component: 'Fields.ConfirmationBox'
  },
  phone: {
    name: 'forms.field_type.phone',
    type: 'phone',
    icon: <PhoneIcon />,
    color: '#aad368',
    component: 'Fields.Phone'
  },
  currency: {
    name: 'forms.field_type.currency',
    type: 'number',
    icon: '£',
    color: '#8b7fc7',
    component: 'Fields.Currency'
  },
  currency_conversion: {
    name: 'forms.field_type.currency_conversion',
    type: 'number',
    icon: '£↔$',
    color: '#feb44e',
    component: 'Fields.CurrencyConversion'
  },
  date: {
    name: 'forms.field_type.date',
    type: 'number',
    icon: <DateRangeIcon />,
    color: '#ff9b00',
    component: 'Fields.Date'
  },
  email_address: {
    name: 'forms.field_type.email_address',
    type: 'email_address',
    icon: <EmailIcon />,
    color: '#881003',
    component: 'Fields.Email'
  },
  file_upload: {
    name: 'forms.field_type.file_upload',
    type: 'file',
    icon: <CloudUploadIcon />,
    color: '#007836',
    component: 'Fields.FileUpload'
  },
  // profile_field: {
  //   name: 'forms.field_type.profile_field',
  //   type: 'text',
  //   icon: <PersonIcon />,
  //   color: '#06f1e7',
  //   component: 'Fields.ProfileField'
  // },
  instructional_text: {
    name: 'forms.field_type.instructional_text',
    type: 'text',
    icon: <InfoIcon />,
    color: '#aad368',
    component: 'Fields.InstructionalText',
    cantBeRequired: true
  },
  user_lookup: {
    name: 'forms.field_type.user_lookup',
    type: 'text',
    icon: <PersonIcon />,
    color: '#4886ff',
    component: 'Fields.UserLookup'
  },
  lookup: {
    name: 'forms.field_type.lookup',
    type: 'text',
    icon: <FindInPageIcon />,
    color: '#007836',
    component: 'Fields.Lookup'
  },
  thirdfort: {
    name: 'forms.field_type.thirdfort',
    type: 'text',
    icon: <Image src="icons/thirdfort.png" />,
    color: '#fff',
    component: 'Fields.Thirdfort'
  },
  credas: {
    name: 'forms.field_type.credas',
    type: 'text',
    icon: <Image src="icons/credas-logo.svg" />,
    color: '#00142d',
    component: 'Fields.Credas'
  },
  worldcheck: {
    name: 'forms.field_type.worldcheck',
    type: 'text',
    icon: <Image src="icons/refinitiv.png" />,
    color: '#fff',
    component: 'Fields.Worldcheck'
  },
  worldcheck_org: {
    name: 'forms.field_type.worldcheck_org',
    type: 'text',
    icon: <Image src="icons/refinitiv.png" />,
    color: '#fff',
    component: 'Fields.WorldcheckOrg'
  },
  section_break: {
    name: 'forms.field_type.section_break',
    type: 'text',
    icon: '§',
    color: '#4886ff',
    component: () => null,
    cantBeRequired: true
  }
};

const sections = {
  input_fields: [
    'text',
    'long_text',
    'link',
    'number',
    'phone',
    'dropdown',
    'multiple_choice',
    'yes_no',
    'date',
    'confirmation',
    'currency',
    'email_address',
    'file_upload'
  ],
  text_sections: ['section_break', 'instructional_text'],
  complex_fields: [
    'currency_conversion',
    'credas',
    'lookup',
    'user_lookup',
    'score',
    'thirdfort',
    'worldcheck',
    'worldcheck_org'
  ]
};

let typesWithHeaders = {};
let sectionNames = Object.keys(sections);

sectionNames.forEach(sectionName => {
  let subFieldNames = sections[sectionName];
  typesWithHeaders[sectionName] = {
    name: 'forms.field_type.' + sectionName,
    type: 'section_header'
  };
  subFieldNames.forEach(one => {
    typesWithHeaders[one] = fieldTypes[one];
  });
});

export const fieldTypesWithHeaders = typesWithHeaders;
