// import React from 'react';
import Cookies from 'universal-cookie';
import jwt from 'jsonwebtoken';
import getTenant from './getTenant';

const cookies = new Cookies();

export default function getJWT() {
  let token = cookies.get(`auth-header-${getTenant()}`) || '';
  token = token.replace(/(Bearer: )/, '');

  return token ? jwt.decode(token) : null;
}

export function getEncodedToken() {
  let token = cookies.get(`auth-header-${getTenant()}`) || '';
  token = token.replace(/(Bearer: )/, '');

  return token;
}

window.getJWT = getJWT;
