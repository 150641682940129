import dotProp from 'dot-prop-immutable';
import generateId from '../../../utils/generateId';

export const LOAD_ASSET = 'state/reportBuilder/LOAD_ASSET';
export const UPDATE_ASSET = 'state/reportBuilder/UPDATE_ASSET';
export const REORDER_ROWS = 'state/reportBuilder/REORDER_ROWS';
export const ADD_ROW = 'state/reportBuilder/ADD_ROW';
export const DUPLICATE_ROW = 'state/reportBuilder/DUPLICATE_ROW';
export const DELETE_ROW = 'state/reportBuilder/DELETE_ROW';
export const UPDATE_ITEM = 'state/reportBuilder/UPDATE_ITEM';
export const DELETE_ITEM = 'state/reportBuilder/DELETE_ITEM';
export const INSERT_ITEM = 'state/reportBuilder/INSERT_ITEM';

const generateItemId = type =>
  type.split('.').slice(-1)[0] + '_' + generateId();

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getPathFromId = (root, id) => {
  let path = 'children.';
  let el = root;
  const _ids = id.split('.');

  _ids.forEach((_id, i) => {
    const index = el.children.findIndex(
      child => child && child._id && child._id.includes(_id)
    );
    if (i > 0) {
      path += '.children.';
    }
    path += index;
    el = el.children[index];
  });

  return path;
};

export const getChildById = (root, id) =>
  dotProp.get(root, getPathFromId(root, id));

// reducer as default export
const reportBuilder = (state = {}, action) => {
  const { type, payload = {} } = action;
  const { id, _id, ...rest } = payload;
  let children, index, row, path;

  switch (type) {
    case LOAD_ASSET:
      return dotProp.set(state, id, payload);

    case UPDATE_ASSET:
      return dotProp.set(state, id, asset => ({
        ...asset,
        ...payload
      }));

    case REORDER_ROWS:
      return dotProp.set(state, id, asset => ({
        ...asset,
        children: reorder(
          state[id].children,
          payload.startIndex,
          payload.endIndex
        )
      }));

    case ADD_ROW:
      children = dotProp.get(state, `${id}.children`);

      children.push({
        // immutable copy of source item
        ...rest,
        _id: generateItemId(payload.type)
      });

      return dotProp.set(state, id, asset => ({
        ...asset,
        children
      }));

    case DUPLICATE_ROW:
      children = dotProp.get(state, `${id}.children`);
      index = children.findIndex(item => item._id === _id);
      row = dotProp.get(children, index);

      const newId = generateItemId(row.type);

      // generate new ids for both the duplicate row and its children
      const newRow = {
        ...row,
        _id: newId,
        children: row.children.map(w => ({
          ...w,
          _id: newId + '.' + generateItemId(w.type)
        }))
      };

      // insert after selected row
      children.splice(index + 1, 0, newRow);

      return dotProp.set(state, id, asset => ({
        ...asset,
        children
      }));

    case DELETE_ROW:
      children = dotProp
        .get(state, `${id}.children`)
        .filter(item => item._id !== _id);

      return dotProp.set(state, id, asset => ({
        ...asset,
        children
      }));

    case UPDATE_ITEM:
      path = getPathFromId(state[id], _id);

      return dotProp.set(state, `${id}.${path}`, item => ({
        ...item,
        ...payload.props
      }));

    case DELETE_ITEM:
      path = getPathFromId(state[id], _id);

      return dotProp.set(state, `${id}.${path}`, null);

    case INSERT_ITEM:
      return dotProp.set(state, `${id}.${payload.path}`, {
        ...payload.item,
        _id: _id + '.' + generateItemId(payload.item.type)
      });

    default:
      return state;
  }
};

export default reportBuilder;