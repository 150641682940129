import dotProp from 'dot-prop-immutable';

const SUBMISSION_STARTED = 'SUBMISSION_STARTED';
const SUBMISSION_COMPLETE = 'SUBMISSION_COMPLETE';

// reducer as default export
const forms = (state = {}, action) => {
  switch (action.type) {
    case SUBMISSION_STARTED:
      return dotProp.set(state, action.id, true);
    case SUBMISSION_COMPLETE:
      return dotProp.delete(state, action.id);
    default:
      return state;
  }
};

export default forms;

// action creators
export const submissionStarted = id => ({
  type: SUBMISSION_STARTED,
  id: id
});

export const submissionComplete = id => ({
  type: SUBMISSION_COMPLETE,
  id: id
});
