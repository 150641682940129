import fetch from 'cross-fetch';
import { push } from 'connected-react-router';
import { openBanner, closeBanner, submissionStarted, submissionComplete } from '../actionCreators';
import getTenant from '../../utils/getTenant';

const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

const resetPassword = (state = null, action) => {
  switch (action.type) {
    case RESET_PASSWORD_SUCCESS:
      // start a redirect
      return action.username;
    default:
      return state;
  }
};

export default resetPassword;

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
function resetPasswordRequest(username) {
  submissionStarted('resetPassword');
  return {
    type: RESET_PASSWORD_REQUEST,
    username
  };
}

function resetPasswordSuccess(username) {
  submissionComplete('resetPassword');
  return {
    type: RESET_PASSWORD_SUCCESS,
    username,
    receivedAt: Date.now()
  };
}

export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
function resetPasswordFailure(username, e) {
  submissionComplete('resetPassword');
  return {
    type: RESET_PASSWORD_FAILURE,
    username,
    data: e,
    receivedAt: Date.now()
  };
}

export function callResetPassword(values, url, method = 'POST', sleepTime = 0) {
  closeBanner('message200');
  closeBanner('message401');
  closeBanner('message422');
  closeBanner('message500');
  const { userName } = values;
  return dispatch => {
    dispatch(resetPasswordRequest(userName));
    var fetchParams = {
      method: method,
      headers: {
        'x-tenant-id': getTenant(),
        'Content-Type': 'application/json'        
      }
    };
    var queryParams = { 
      info: [
        {
          userName: userName
        }
      ]
    };
    if (method === 'POST') {
      fetchParams.body = JSON.stringify(queryParams);
    } else {
      function objToQueryString(obj) {
        const keyValuePairs = [];
        for (let i = 0; i < Object.keys(obj).length; i += 1) {
          keyValuePairs.push(`${encodeURIComponent(Object.keys(obj)[i])}=${encodeURIComponent(Object.values(obj)[i])}`);
        }
        return keyValuePairs.join('&');
      }
      url += '?' + objToQueryString(queryParams);
    }
    let resp;
    return fetch(url, fetchParams)
      .then(response => {
        return new Promise(function (resolve) {
          sleep(sleepTime).then(() => resolve(response));
        });
      })
      .then(response => {
        resp = response;        
        if (response.status !== 200) {
          throw new Error(response);
        } else {
          dispatch(resetPasswordSuccess(userName));
          dispatch(push(values.targetURL));
        }
      })
      .catch(e => {
        dispatch(resetPasswordFailure(userName, e));
        if (!resp || resp.status >= 500) {
          if (values.ErrorURL500) {
            dispatch(push(values.ErrorURL500));
          } else {
            openBanner('message500');
          }
        } else if (resp.status === 422) {
          openBanner('message422');
        } else if (resp.status === 401) {
          openBanner('message401');
        }
      });
  };
}
