const SET_FILTERS = 'gridFilters/SET_FILTERS';

// reducer as default export
const gridFilters = (state = {}, action) => {
  switch (action.type) {
    case SET_FILTERS:
      return action.payload;
    default:
      return state;
  }
};

export default gridFilters;

// action creators
export const setFilters = id => ({
  type: SET_FILTERS,
  payload: id
});
