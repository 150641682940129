import dotProp from 'dot-prop-immutable';

const OPEN_DRAWER = 'state/drawers/OPEN_DRAWER';
const CLOSE_DRAWER = 'state/drawers/CLOSE_DRAWER';

// reducer as default export
const drawers = (state = {}, action) => {
  switch (action.type) {
    case OPEN_DRAWER:
      return dotProp.set(state, action.payload, true);
    case CLOSE_DRAWER:
      return dotProp.delete(state, action.payload);
    default:
      return state;
  }
};

export default drawers;

// action creators
export const openDrawer = id => ({
  type: OPEN_DRAWER,
  payload: id
});

export const closeDrawer = id => ({
  type: CLOSE_DRAWER,
  payload: id
});
