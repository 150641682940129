export const defaultLength = 16;

/**
 * Generates a unique string of decimals, of a given length.
 * 
 * Note that the expected number of generations before a collision is 10^(N/2), where N is the number of digits (https://en.wikipedia.org/wiki/Birthday_problem#Cast_as_a_collision_problem). The default here is 16 digits, or about 1e8 generations without a collision.
 */
const generateId = (length = defaultLength) => {
  const number =
    (Math.pow(10, length) + Date.now()) * Math.random() +
    // for padding
    Math.pow(10, length + 1);

  return number.toFixed(0).slice(-length);
};

export default generateId;