import React from 'react';
import store from '../store';
import { getLoadable } from './components';
import { TextString } from '../components/Text';
import templates from '../data/templates';
import interpolateProps from './interpolateProps';
import ErrorBoundary from './ErrorBoundary';

const expandShorthand = el => {
  const key = Object.keys(el)[0];
  return {
    ...el,
    type: 'Text',
    variant: key === 'p' ? null : key,
    content: el[key]
  };
};

/**
 * Given an object describing a React element with
 * further element descriptions nested in its props,
 * returns a tree of React elements.
 *
 * @param {object} el Description of an element
 * @param {*} i index when iterating
 */
function createTree(el, i) {
  let presets = store.getState().content.presets;

  // if we already have a valid element, return
  if (React.isValidElement(el)) {
    return el;
  }

  if (el === null) {
    return null;
  }

  // if we have a text node, return a `TextString`
  if (typeof el === 'string') {
    return createTree(<TextString content={el} />);
  }

  // shorthand
  if (
    typeof el.type !== 'string' &&
    (el.p || el.h1 || el.h2 || el.h3 || el.h4 || el.h5 || el.h6)
  ) {
    el = expandShorthand(el);
  }

  // parse the element object
  let { type, children, ...props } = el;

  if (props.dangerouslySetInnerHTML) {
    console.error(
      'Removing the prop "dangerouslySetInnerHTML" to prevent XSS attacks.'
    );
    delete props.dangerouslySetInnerHTML;
  }

  if (type in templates) {
    props = interpolateProps(props, templates[type]);
    delete props.type;
    type = templates[type].type;
  }

  /**
   * 3 choices for el.type:
   * - lowercase string for native DOM tags
   * - capitalized string for calling getComponent(type)
   * - a component function or class
   */
  const isString = typeof type === 'string';
  const isFunction = typeof type === 'function';
  const isLowerCase = isString && type === type.toLowerCase();

  if (!isString && !isFunction) {
    throw new Error(`bad type for el.type: ${typeof type}`);
  }

  // props: merge custom props into preset props
  if (isString && type in presets && !type.skipPresets) {
    props = Object.assign({}, presets[type], props);
  }

  // children: create elements from the children
  children = Array.isArray(children) ? children.map(createTree) : children;

  if (isString) {
    // type: if first letter is uppercase, get the component through Loadable
    type = isLowerCase ? type : getLoadable(type);
  }

  if (!isLowerCase) {
    // return the created React element with an error boundary
    return (
      // spread props here to expose them if accessing children props from parent
      <ErrorBoundary key={i} {...props}>
        {React.createElement(type, props, children)}
      </ErrorBoundary>
    );
  }

  // return the created React element
  return React.createElement(type, { key: i, ...props }, children);
}

export default createTree;
