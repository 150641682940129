import React, { createContext } from 'react';
import { Router } from './store/modules/history';
import PageTransitionGroup from './internal-components/PageTransitionGroup';
import LocaleWidget from './internal-components/LocaleWidget';
import KeyboardNavigation from './internal-components/KeyboardNavigation';
import ThemeProvider from './components/ThemeProvider';
import store from './store';
import { Provider as StoreProvider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import CheatPanel from './internal-components/CheatPanel';
import SetDocumentTitle from './internal-components/SetDocumentTitle';
import GlobalTypography from './internal-components/GlobalTypography';
import FetchCallbacks from './internal-components/FetchCallbacks';
import GetGlobalProps from './GetGlobalProps';
import GetMobileNavAPI from './GetMobileNavAPI';
import Loader, { OmnitrackLoader, DocusignLoader } from './components/Loader';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

export const GlobalPropsContext = createContext(null);
export const MobileNavAPIContext = createContext(null);

const App = () => {
  const globalProps = GetGlobalProps();
  const mobileNavAPI = GetMobileNavAPI();

  return (
    <>
      <Loader isLoading={globalProps.isLoading} />
      <OmnitrackLoader isLoading={globalProps.isOTLoading} />

      <GlobalPropsContext.Provider value={globalProps}>
        <MobileNavAPIContext.Provider value={mobileNavAPI}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <StoreProvider store={store}>
              <ThemeProvider theme="light">
                <FetchCallbacks>
                  <DocusignLoader isLoading={globalProps.isDocusigning} />
                  <CssBaseline />
                  <LocaleWidget />
                  <SetDocumentTitle />
                  <GlobalTypography />
                  <Router>
                    <>
                      <KeyboardNavigation />
                      <CheatPanel />
                      <PageTransitionGroup />
                    </>
                  </Router>
                </FetchCallbacks>
              </ThemeProvider>
            </StoreProvider>
          </MuiPickersUtilsProvider>
        </MobileNavAPIContext.Provider>
      </GlobalPropsContext.Provider>
    </>
  );
};

export default App;
