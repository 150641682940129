import { callLogin } from './modules/login';
import { callResetPassword } from './modules/resetPassword';
import { callSetPassword } from './modules/setPassword';
import { push } from 'connected-react-router';
import { getPages } from './selectors';
import { viewed as viewedAC } from '../store/modules/state/viewed';
import { complete as completeAC } from './modules/state/complete';
import {
  openLightbox as openLightboxAC,
  closeLightbox
} from './modules/state/lightboxes';
import {
  openDrawer as openDrawerAC,
  closeDrawer as closeDrawerAC
} from './modules/state/drawers';
import {
  openBanner as openBannerAC,
  closeBanner as closeBannerAC
} from './modules/state/banners';
import {
  submissionStarted as submissionStartedAC,
  submissionComplete as submissionCompleteAC
} from './modules/state/forms';
import store from './';

// TODO: sort bound and not bound action creators
// BAC
const go = id => {
  store.dispatch(push('/' + id));
};

// BAC
const setProfileAndStart = profile => {
  store.dispatch({ type: 'SET_PROFILE', payload: { profile } });
  go(getPages()[1].id);
};

// BAC
const openLightbox = (id, callback) => {
  store.dispatch(openLightboxAC(id, callback));
};

// BAC
const openDrawer = id => {
  store.dispatch(openDrawerAC(id));
};

// BAC
const closeDrawer = id => {
  store.dispatch(closeDrawerAC(id));
};

const openBanner = id => {
  store.dispatch(openBannerAC(id));
};

// BAC
const closeBanner = id => {
  store.dispatch(closeBannerAC(id));
};

// BAC
const submissionStarted = id => {
  store.dispatch(submissionStartedAC(id));
};

// BAC
const submissionComplete = id => {
  store.dispatch(submissionCompleteAC(id));
};

const complete = id => store.dispatch(completeAC(id));

// const goToNext = () => {
//   const current = store.getState().router.location.pathname.split('/')[1];
//   const pages = getPages();
//   const index = pages.indexOf(current);

//   if (index === pages.length - 1) {
//     // on last page
//     console.warn('already on last page');
//     return;
//   }

//   go(pages[index + 1]);
// };

const viewed = id => store.dispatch(viewedAC(id));

const actions = {
  callLogin,
  callResetPassword,
  callSetPassword,
  closeBanner,
  closeDrawer,
  closeLightbox,
  complete,
  go,
  openBanner,
  openDrawer,
  openLightbox,
  setProfileAndStart,
  submissionComplete,
  submissionStarted,
  viewed
};

export {
  callLogin,
  callResetPassword,
  callSetPassword,
  closeBanner,
  closeDrawer,
  closeLightbox,
  complete,
  go,
  openBanner,
  openDrawer,
  openLightbox,
  setProfileAndStart,
  submissionComplete,
  submissionStarted,
  viewed
};

export default actions;
