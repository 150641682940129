import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import format from '../utils/format';

export default function useTranslations() {
  // strict equality comparison for string (default in v7.1.0-alpha.5)
  const locale = useSelector(state => state.state.locale);

  // shallow equality for object
  const messages = useSelector(
    state => state.content.translations,
    shallowEqual
  );

  return React.useCallback(
    (key, values) => format(messages, locale, key, values),
    [messages, locale]
  );
}
