import defaultThemes from './default/themes.json';
import { createMuiTheme } from '@material-ui/core/styles';

const themes = defaultThemes;

Object.keys(themes).forEach(key => {
  themes[key] = createMuiTheme(themes[key]);
});

export default themes;
