import React, { useState, useEffect } from 'react';
import { Snackbar as MuiSnackbar, IconButton } from '@material-ui/core';
import useTranslations from '../hooks/useTranslations';
import dotProp from 'dot-prop';
import styled from 'styled-components/macro';
import vanillaFetchAPI from '../utils/fetchAPI';

import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/HighlightOff';
import WarningIcon from '@material-ui/icons/ErrorOutline';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Loader from '../components/Loader';

const reducer = (state, action) => {
  const { type, id, message, noAutoHide, delay } = action;
  switch (type) {
    case 'open':
      return { ...state, [id]: { message, noAutoHide, delay } };

    case 'close':
      delete state[id];

      return { ...state };
    default:
      return state;
  }
};

const colors = {
  error: {
    background: '#f5d3ce',
    color: '#6e2f1a',
    borderColor: '#ef7564'
  },
  warning: {
    background: '#fff9ed',
    color: '#4f3202',
    borderColor: '#ffd576'
  },
  success: {
    background: '#d6ecd2',
    color: '#1f3e14',
    borderColor: '#7bc86c'
  }
};

const Snackbar = styled(MuiSnackbar)`
  && {
    [class*='MuiSnackbarContent-root'] {
      background: ${({ variant }) => colors[variant].background};
      color: ${({ variant }) => colors[variant].color};
      border-left: 3px solid ${({ variant }) => colors[variant].borderColor};
      border-radius: 0;
      font-weight: 700;
    }

    [class*='MuiSnackbarContent-message'] {
      /* prevents IE11 text overflow in flex child */
      width: 100%;
    }

    .uig-snackbar-icon {
      color: ${({ variant }) => colors[variant].borderColor};

      vertical-align: middle;
      margin: -0.3em 0.7ch -0.2em 0;
    }

    .uig-snackbar-content {
      display: flex;
      align-items: center;
    }

    .uig-snackbar-button {
      margin: -16px -16px -16px 0;
    }
  }
`;

const FetchSnackbar = ({ id, state, dispatch, variant = 'success', icon }) => {
  const t = useTranslations();
  const snackbar = state[id] || {};
  const { message, noAutoHide, delay } = snackbar;
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);

    // We need to use delay here so that the message will disappear after the snackbar animation ened and the snackbar left the screen (timer should be the same as the animation length)
    setTimeout(() => {
      dispatch({ type: 'close', id });
    }, 250);
  };

  useEffect(() => {
    setOpen(Boolean(message));
  }, [message]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      TransitionComponent={Slide}
      TransitionProps={{ direction: 'down' }}
      ContentProps={{ elevation: 6 }}
      variant={variant}
      open={open}
      autoHideDuration={noAutoHide ? null : delay ? delay : 3000}
      onClose={onClose}
      message={
        <span className="uig-snackbar-content">
          {icon}
          <span data-testid={id} className="uig-snackbar-text">
            {t(message)}
          </span>
          {noAutoHide && (
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={onClose}
              className="uig-snackbar-button"
            >
              <CloseIcon />
            </IconButton>
          )}
        </span>
      }
    />
  );
};

export const FetchCallbacksContext = React.createContext({
  defaultCallbacks: {},
  fetchAPI: vanillaFetchAPI,
  openSnackbar: (id, message, noAutoHide, delay) => {},
  displayLoader: state => {}
});

export default function FetchCallbacks({ children }) {
  const [state, dispatch] = React.useReducer(reducer, {});
  const [isLoading, setIsLoading] = useState(false);
  const openSnackbar = React.useCallback(
    (id, message, noAutoHide = false, delay) => {
      dispatch({
        type: 'open',
        id: `snackbar-${id}`,
        message,
        noAutoHide,
        delay
      });
    },
    [dispatch]
  );

  const defaultCallbacks = React.useMemo(
    () => ({
      handleNetworkError: e => {
        dispatch({
          type: 'open',
          id: 'snackbar-error',
          message: 'Could not connect to the network.',
          noAutoHide: true
        });

        console.warn({ caught: e });
      },

      handleServerError: e => {
        dispatch({
          type: 'open',
          id: 'snackbar-error',
          message: 'Sorry, we encountered a problem. Please try again later.',
          noAutoHide: true
        });

        console.warn({ caught: e });
      },

      handleValidationError: data => {
        console.warn({ caught: data });

        const message = dotProp.get(data, 'errors.0.message');

        dispatch({
          type: 'open',
          id: 'snackbar-error',
          message: `Invalid: ${message}`,
          noAutoHide: true
        });
      },
      handleLicensingError: data => {
        console.warn({ caught: data });

        //const message = dotProp.get(data, 'errors.0.message');
        const message =
          'You are unable to perform this operation because your Omnitrack plan limit has been reached. You can expand your limit by contacting support@vinciworks.com.';

        dispatch({
          type: 'open',
          id: 'snackbar-error',
          message: `License Error: ${message}`,
          noAutoHide: true
        });
      }
    }),
    []
  );

  const fetchAPI = React.useCallback(
    (endpoint, options) =>
      vanillaFetchAPI(endpoint, {
        ...defaultCallbacks,
        ...options
      }),
    [defaultCallbacks]
  );

  const contextValue = React.useMemo(
    () => ({
      openSnackbar,
      defaultCallbacks,
      fetchAPI,
      displayLoader: setIsLoading
    }),
    [defaultCallbacks, fetchAPI, openSnackbar]
  );

  React.useEffect(() => {
    window.openSnackbar = openSnackbar;
    window.fetchCallbacks = contextValue;
  }, [contextValue, openSnackbar]);

  return (
    <>
      <FetchSnackbar
        id="snackbar-error"
        variant="error"
        state={state}
        dispatch={dispatch}
        noAutoHide
        icon={<ErrorIcon className="uig-snackbar-icon" />}
      />
      <FetchSnackbar
        id="snackbar-success"
        state={state}
        dispatch={dispatch}
        icon={<SuccessIcon className="uig-snackbar-icon" />}
      />
      <FetchSnackbar
        id="snackbar-warning"
        variant="warning"
        state={state}
        dispatch={dispatch}
        noAutoHide
        icon={<WarningIcon className="uig-snackbar-icon" />}
      />
      <Loader isLoading={isLoading} />
      <FetchCallbacksContext.Provider value={contextValue}>
        {children}
      </FetchCallbacksContext.Provider>
    </>
  );
}

// export const Test = withFetchCallbacks(({ fetchCallbacks }) => {
//   return null;
// });

// higher order component
export function withFetchCallbacks(Component) {
  return function WithFetchCallbacks(props) {
    return (
      <FetchCallbacksContext.Consumer>
        {value => <Component {...props} fetchCallbacks={value} />}
      </FetchCallbacksContext.Consumer>
    );
  };
}
