import React from 'react';
import IeEndOfLifeMsg from './IeEndOfLifeMsg';
// import getJWT from './getJWT';
// import apm from '../rum';

function detectIE() {
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  var edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    //You can also log the error to an error reporting service
    //logErrorToMyService(error, info);
    //    apm.captureError(error, {
    //     custom: {
    //     errorInfo: info
    // }
    //});
  }

  render() {
    let isIE = detectIE();
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div data-testid="error">
          {isIE ? (
            <IeEndOfLifeMsg />
          ) : (
            <p>
              <em>Something went wrong.</em>{' '}
            </p>
          )}
          {!isIE && this.state.error && (
            <p>
              <small>
                <p>Error code:</p>
                <textarea
                  rows={4}
                  style={{ fontFamily: 'monospace' }}
                  value={btoa(this.state.error.stack)}
                  readOnly
                />{' '}
              </small>
            </p>
          )}
        </div>
      );
    }

    // return this.props.children;

    const { children, ...rest } = this.props;

    // clone props here that may be passed to error boundary
    return React.cloneElement(React.Children.only(children), rest);
  }
}

export default ErrorBoundary;
