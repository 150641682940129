// https://github.com/remarkjs/remark/tree/master/packages/remark-parse#extending-the-parser

function shortcodes(options) {
  const Parser = this.Parser;
  const tokenizers = Parser.prototype.inlineTokenizers;
  const methods = Parser.prototype.inlineMethods;

  tokenizers.shortcode = tokenizeShortcode(options);
  tokenizers.shortcode.locator = locateShortcode;

  methods.splice(methods.indexOf('text'), 0, 'shortcode');
}

const tokenizeShortcode = (options = {}) => (eat, value, silent) => {
  const start = options.start || '[[';
  const end = options.end || ']]';

  const escape = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  const regex = new RegExp(
    `^${escape(start)}\\s*(\\S+) (((?!${escape(end)}).)*)\\s*${escape(end)}`
  );

  var match = regex.exec(value);

  if (match) {
    if (silent) {
      return true;
    }

    return eat(match[0])({
      type: 'shortcode',
      component: match[1],
      props: parseProps(match[2])
    });
  }
};

const locateShortcode = (value, fromIndex) => value.indexOf('(%', fromIndex);

const parseProps = str => {
  const props = {};
  const matches = str.match(/(\S+)="([^"]+)"/g);

  if (!matches) {
    throw new Error('had a problem');
  }

  matches
    .map(s => s.match(/(\S+)="([^"]+)"/))
    .forEach(arr => {
      props[arr[1]] = arr[2];
    });

  return props;
};

export default shortcodes;
