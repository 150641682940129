import styled from 'styled-components/macro';
import Colors from '../../../internal-components/Colors';
import GoodPagesIds from './GoodPagesIds';

export const Sizes = {
  MOBILE_NAV_BAR_HEIGHT: 60,
  NAV_BAR_HEIGHT: 80,
  FOOTER_HEIGHT: 70
};

export const Section = styled.section`
  height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`;

export const StandardContainer = styled.div`
  padding: 2em;
  background: ${Colors.BACKGROUND_GREY};
  flex: 1 1 auto;

  ${({ pageId }) => {
    if (GoodPagesIds.includes(pageId)) {
      return 'display: flex; flex-direction: column;';
    }
  }}

  @media (max-width: 800px) {
    padding: 24px 16px;
  }

  @media (max-width: 500px) {
    padding: 0;
  }

  @media print {
    padding: 0;
  }

  //   background: -webkit-linear-gradient(
  //     to right,
  //     #d7dde8,
  //     ${Colors.BACKGROUND_GREY}
  //   ); /* Chrome 10-25, Safari 5.1-6 */
  //   background: linear-gradient(
  //     to right,
  //     #d7dde8,
  //     ${Colors.BACKGROUND_GREY}
  //   ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`;

export const CenteredContainer = styled.div`
  display flex;
  position: fixed;
  left: 0;
  right: 0;
  top:  0;
  bottom: 0;
  margin: auto;
  width: 45vw;
  height: 40vh;
  border-top: 1px solid ${Colors.OT_GREY};
  border-bottom: 1px solid ${Colors.OT_GREY};
`;

export const SimpleFlexContainer = styled.div`
  display: flex;
  flex-direction: ${({ $row }) => ($row ? 'row' : 'column')};
  ${({ $containerCSS }) => $containerCSS}
`;
