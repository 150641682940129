import Cookies from 'universal-cookie';
import jwt from 'jsonwebtoken';
import queryString from 'query-string';
import getTenant from './getTenant';

const cookies = new Cookies();

function isTokenValid(token) {
  var decoded, tokenExpiry;
  const now = new Date();
  if (token) {
    decoded = jwt.decode(token);
    if (decoded) {
      tokenExpiry = decoded.exp * 1000;
      if (tokenExpiry > +now) {
        return true;
      }
    }
  }
  return false;
}

export default function applyJWT() {
  const query = queryString.parse(window.location.search);

  if (query && query.jwt) {
    const newToken = query.jwt;
    const authHeader = cookies.get(`auth-header-${getTenant()}`) || '';
    const existingToken = authHeader.replace(/(Bearer: )/, '');
    if (isTokenValid(existingToken)) {
      return;
    }

    if (isTokenValid(newToken)) {
      cookies.set(`auth-header-${getTenant()}`, `Bearer: ${newToken}`, {
        path: '/'
      });
    }
  }
}

applyJWT();
