/**
 * REDUCERS
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

// TODO: key "state" is awkward
// component state
import autosave from './state/autosave';
import banners from './state/banners';
import completed from './state/complete';
import drawers from './state/drawers';
import fieldChanged from './state/fieldChanged';
import formBuilder from './state/formBuilder';
import forms from './state/forms';
import gridColumns from './state/gridColumns';
import gridFilters from './state/gridFilters';
import gridSort from './state/gridSort';
import groups from './state/groups';
import instructionsVisible from './state/instructionsVisible';
import lightboxes from './state/lightboxes';
import locale from './state/locale';
import newGroup from './state/newGroup';
import reportBuilder from './state/reportBuilder';
import resetPassword from './resetPassword';
import snackbarMessage from './state/snackbarMessage';
import turnedOffSSO from './state/turnedOffSSO';
import userManagementTabs from './state/userManagementTabs';
import userProfile from './state/userProfile';
import viewed from './state/viewed';

// content data
import assets from './content/assets';
import config from './content/config';
import locales from './content/locales';
import translations from './content/translations';
import pages from './content/pages';
import presets from './content/presets';
import themes from './content/themes';

import login from './login';
import history from './history';

import { LOCATION_CHANGE } from 'connected-react-router';

// // identity reducer, for placeholders
// const I = (x = {}) => x;

const appReducer = combineReducers({
  router: connectRouter(history),
  state: combineReducers({
    autosave,
    banners,
    completed,
    drawers,
    fieldChanged,
    formBuilder,
    forms,
    gridColumns,
    gridFilters,
    gridSort,
    groups,
    history: (state = [], action = {}) => {
      if (action.type === LOCATION_CHANGE) {
        return [...state, action.payload.location.pathname];
      }

      return state;
    },
    instructionsVisible,
    lightboxes,
    locale,
    login,
    newGroup,

    reportBuilder,
    resetPassword,
    snackbarMessage,
    turnedOffSSO,
    userManagementTabs,
    userProfile,
    viewed
  }),

  content: combineReducers({
    assets,
    config,
    locales,
    translations,
    pages,
    presets,
    themes
  })
});

export default appReducer;
