import dotProp from 'dot-prop';

/**
 * A valid dot-prop address has at least one dot inside and no spaces
 */
const isDotPropPathValid = dotPropPath => /^\S+(\.\S+)+$/.test(dotPropPath);

/**
 * Returns a value from a theme object given the path. If the path is not a valid dot-prop path, returns undefined.
 *
 * @param {object} theme A theme object
 * @param {string} dotPropPath Path to value
 */
export default function getThemeValue(theme, dotPropPath) {
  if (!isDotPropPathValid(dotPropPath)) {
    return;
  }

  return dotProp.get(theme, dotPropPath);
}
