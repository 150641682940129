import dotProp from 'dot-prop-immutable';

const SET_GROUP = 'groups/SET_GROUP';

// reducer as default export
const groups = (state = {active: {name: 'all users', filter:""}}, action) => {
  switch (action.type) {
    case SET_GROUP:
      return dotProp.set(state, 'active', action.payload);
    default:    
      return state;
  }
};

export default groups;

// action creators
export const setGroup = id => ({
  type: SET_GROUP,
  payload: id
});
