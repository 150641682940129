import React from 'react';
import Markdown from 'react-markdown';
import { connect } from 'react-redux';
import format from '../utils/format';
import { editTranslation } from '../store/modules/content/translations';
import styled from 'styled-components/macro';
import shortcodes from '../utils/shortcodes';
import createTree from '../utils/createTree';
import { Link } from '@material-ui/core';

const mapStoreToProps = store => ({
  translations: store.content.translations,
  locale: store.state.locale
});

// calls createTree on the response from the remark parser plugin `shortcodes`
const renderShortcode = ({ component, props }) =>
  createTree({ ...props, type: component });

const FormattedMessageMarkdown = ({
  fixNumberPrefixes,
  translations,
  locale,
  content,
  values,
  allowedTypes = [
    'root',
    'text',
    'strong',
    'emphasis',
    'link',
    'code',
    'inlineCode',
    'image'
  ]
}) => {
  let message = format(translations, locale, content, values);

  if (fixNumberPrefixes) {
    if (!allowedTypes.includes('listItem') && message.match(/^(\d+)\.(\s+)(\S+)/)) {
      message = message.replace(/^(\d+)\.(\s+)(\S+)/, '$1.&nbsp;$3')
    }
  }

  return (
    <Markdown
      source={message}
      unwrapDisallowed
      plugins={[[shortcodes, { start: '[[', end: ']]' }]]}
      renderers={{
        shortcode: renderShortcode,
        link: props => <Link target="_blank" rel="noopener" {...props} />
      }}
      allowedTypes={allowedTypes}
      linkTarget="_blank"
    />
  );
};

const TextString = connect(mapStoreToProps)(
  React.memo(FormattedMessageMarkdown)
);

const variantMap = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 'p',
  subtitle2: 'p',
  body1: 'p',
  body2: 'p',
  caption: 'p',
  button: 'span',
  overline: 'span'
};

const Typography = React.forwardRef((props, ref) => {
  const { variant, component, ...rest } = props;
  const v = variant || 'body2';

  // create a Styled Component, using the tag name from the `variantMap`, and the styles from the theme.
  const Tag = styled[component || variantMap[v] || 'p'](
    ({ theme }) => theme.typography[v]
  );

  return <Tag {...rest} ref={ref} />;
});

const Text = React.forwardRef((props, ref) => {
  let {
    content,
    children,
    variant,
    defaultMessage,
    style,
    className,
    dispatch,
    translations,
    locale,
    allowedTypes,
    ...otherProps
  } = props;

  // TODO really solve control of margins
  if (variant === 'paragraph') {
    variant = 'body2';
  }

  let onClick = null;

  if (process.env.NODE_ENV === 'development') {
    onClick = e => {
      if (e.ctrlKey) {
        dispatch(
          editTranslation(
            locale,
            content,
            prompt('Please type some new text', '')
          )
        );
      }
    };
  }

  let TextResult = null;
  if ((!variant || variant === 'body2') && content) {
    TextResult = (
      <>
        {content.split('\n').map((line, i) => (
          <>
            {i > 0 && <br />}
            <TextString
              key={i}
              content={line}
              defaultMessage={defaultMessage}
              allowedTypes={allowedTypes}
            />
          </>
        ))}
      </>
    );
  }

  return (
    <Typography
      variant={variant}
      {...otherProps}
      style={{ position: 'relative', ...style }}
      className={className}
      onClick={onClick}
      ref={ref}
    >
      {TextResult ? (
        TextResult
      ) : (
        <TextString
          content={content}
          defaultMessage={defaultMessage}
          allowedTypes={allowedTypes}
        />
      )}
    </Typography>
  );
});

export { TextString };

export default connect(store => ({
  translations: store.content.translations,
  locale: store.state.locale
}))(Text);
