import React from 'react';
import Button from './Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import createTree from '../utils/createTree';
import { connect } from 'react-redux';
import Text, { TextString } from './Text';
import boundActions from '../store/actionCreators';
import { closeLightbox } from '../store/modules/state/lightboxes';
import styled from 'styled-components/macro';

// TODO: configure transition

const StyledDialogContent = styled(DialogContent)`
  &&& {
    padding: ${({ theme }) =>
      `${theme.spacing(1) * 4}px ${theme.spacing(1) * 4}px 0`};
  }
`;

const StyledDialogActions = styled(DialogActions)`
  &&& {
    padding: ${({ theme }) => `${theme.spacing(1) * 2}px`};
    justify-content: flex-start;
  }
`;

// TODO convert to function
export class Lightbox extends React.Component {
  handleClose = () => {
    const { dispatch, id } = this.props;
    dispatch(closeLightbox(id));
  };

  render() {
    const { title, content, buttons } = this.props;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 500, exit: 150 }}
      >
        {title && (
          <DialogContent>
            <Text variant="h6" content={title} />
          </DialogContent>
        )}

        <StyledDialogContent>
          <div>
            <>{content.map(createTree)}</>
          </div>
        </StyledDialogContent>
        <StyledDialogActions>
          {buttons ? (
            buttons.map((button, i) => {
              const handler = () => {
                this.handleClose();
                // TODO custom button actions
                if (button.actions) {
                  button.actions.forEach(a => {
                    const key = Object.keys(a)[0];
                    // need arguments as array; may be given as string
                    const args = Array.isArray(a[key]) ? a[key] : [a[key]];
                    const fn = boundActions[key];

                    // call the actions
                    fn.apply(null, args);
                  });
                }
                if (this.props.callback) {
                  this.props.callback();
                }
              };

              return (
                <Button
                  style={{ padding: '0 1em' }}
                  onClick={handler}
                  color="primary"
                  variant="contained"
                  key={i}
                  autoFocus
                  data-testid={button['data-testid']}
                  disabled={!this.props.open}
                >
                  <TextString content={button.text} />
                </Button>
              );
            })
          ) : (
            <Button onClick={this.handleClose} color="primary" autoFocus>
              OK
            </Button>
          )}
        </StyledDialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (store, props) => ({
  open: store.state.lightboxes[props.id] || false,
  callback: store.state.lightboxes.callback || function() {}
});

export default connect(mapStateToProps)(Lightbox);
