// object to fill with maps
let assets = {};

// directory may not exist
try {
  // use webpack context to read all images, including subdirectories
  const context = require.context(
    './default/assets/',
    true,
    /\.(?:jpg|gif|png|svg|ttf)$/
  );

  // get the keys, like `["./abc.png", "./def.jpg"]`
  const keys = context.keys();

  // loop to add to the `assets` cache
  if (keys)
    keys.forEach(k => {
      // slice 2 because we dont want "./" prefix
      // map will look like `{ 'abc.png' : '/static/media/abc.dd63a4ff.png' }`
      assets[k.slice(2)] = context(k).default;
    });
} catch (e) {
  // console.error(e);
  assets = {};
}

export default assets;
