import store from './';

// TODO this gets called 9 times per page transition!
export const getPages = (state = store.getState()) => {
  const allPages = state.content.pages;

  return allPages;
};

// TODO: gets called 9 times per transition
export const getMenu = state => {
  const builder = state.content.builder || {};
  let profile = state.state.profile;

  const profileKeys = Object.keys(builder);

  if (profileKeys.length === 1) {
    profile = profileKeys[0];
  }

  const menu = profile ? builder[profile] : state.content.pages.map(p => p.id);

  return menu;
};
