/**
 * Interpolates the props of an instance of an element composition (a YAML-defined component composed of library components) into the definition of the element composition. Returns a new element description composed of library components.
 *
 * The interpolation recurses through all properties on a deep clone of the definition, including sub-objects and sub-arrays, in the definition, replacing every string `'$[prop]'` with the corresponding prop in the instance. The corresponding prop may be an object, array, or string. The definition can use multiple string props in one string, for example `'$[foo]-$[bar]'`.
 *
 * @param {object} source Description of instance of composed element
 * @param {object} target Definition of composed element
 * @returns {object} The output of interpolation
 */
export default function interpolateProps(source, target, options = {}) {
  const ignoreRules = options.ignoreRules || [];

  // apply interpolations to clone of the def object
  const output = JSON.parse(JSON.stringify(target));

  // loop through keys
  Object.keys(output).forEach(k => {
    output[k] = processValue(output[k]);
  });

  return output;

  function processValue(v) {
    if (typeof v === 'string') {
      // is the entire value a placeholder? e.g. '$[foo]'
      const fullStringMatch = v.match(/^\$\[([^\]]+)\]$/);

      if (fullStringMatch) {
        const propName = fullStringMatch[1];

        return propName in source ? source[propName] : v;
      }

      // are any substrings placeholders? e.g. '$[foo]-$[bar]'
      const substringMatches = v.match(/\$\[([^\]]+)\]/g);

      if (substringMatches) {
        substringMatches.forEach(match => {
          // extract 'foo' from '${foo}'
          const propName = match.slice(2, -1);

          if (propName in source) {
            // global replace with escaped RegExp
            v = v.replace(new RegExp(escape(match), 'g'), source[propName]);
          }
        });
      }

      return v;
    }

    if (Array.isArray(v)) {
      return v.map(processValue);
    }

    if (typeof v === 'object') {
      const ignoredProps = [];

      for (let i = 0; i < ignoreRules.length; i++) {
        const rule = ignoreRules[i];
        const k = Object.keys(rule.condition)[0];

        if (v[k] && v[k] === rule.condition[k]) {
          ignoredProps.push(rule.ignore);
        }
      }

      Object.keys(v).forEach(k => {
        if (ignoredProps.includes(k)) {
          return;
        }

        v[k] = processValue(v[k]);
      });

      return v;
    }
  }
}

// escape string for RegExp search - https://stackoverflow.com/a/3561711/
const escape = s => s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
