import { translations } from '../../../data';

const translationsFunc = (state = translations, action) => {
  switch (action.type) {
    case 'EDIT_TRANSLATION':
      const { locale, key, value } = action.payload;
      if (!value) {
        return state;
      }
      return {
        ...state,
        [locale]: {
          ...state[locale],
          [key]: value
        }
      };
    default:
      return state;
  }
};

export default translationsFunc;

export const editTranslation = (locale, key, value) => (dispatch, getState) => {
  dispatch({
    type: 'EDIT_TRANSLATION',
    payload: {
      locale,
      key,
      value
    }
  });

  //   const stringifyTranslationFile = obj =>
  //     Object.keys(obj)
  //       .map(k => `${k}: ${obj[k]}`)
  //       .join('\n');
};
