const SET_LOCALE = 'SET_LOCALE';

// reducer as default export
const locale = (state = 'en-GB', action) => {
  switch (action.type) {
    case SET_LOCALE:
      return action.id;
    default:
      return state;
  }
};

export default locale;

// action creator
export const setLocale = id => ({
  type: SET_LOCALE,
  id: id
});
