import moment from 'moment';

const language = navigator.languages ? navigator.languages[0] : navigator.userLanguage;
const localeToImport = language ? language.toLowerCase() : 'en-gb';

if (localeToImport !== 'en' && localeToImport !== 'en-us') {
  try {
    require('moment/locale/' + localeToImport);
  } catch (e) {
    require('moment/locale/en-gb');
  }
}

moment.utc();

export default moment;
