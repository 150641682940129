import dotProp from 'dot-prop-immutable';
import timestamp from '../../../utils/timestamp';

const COMPLETE = 'COMPLETE';

// reducer as default export
const completeFunc = (state = {}, action) => {
  switch (action.type) {
    case COMPLETE:
      return dotProp.set(state, action.id, timestamp());
    default:
      return state;
  }
};

export default completeFunc;

// action creator;
export const complete = id => ({
  type: COMPLETE,
  id: id
});
