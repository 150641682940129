/**
 * Given a form schema, returns a sorted array of both user and admin fields in the form. Admin fields get an additional `isAdminOnly` prop.
 */
export const getAllFields = schema => {
  if (!schema) {
    return [];
  }

  let { fields = [], adminFields = [] } = schema;

  // merge admin and user fields and sort by position prop
  let allFields = [...fields, ...adminFields];

  allFields.sort(
    (a, b) => a.position - b.position
  );

  const adminIds = adminFields.map(f => f.internalId);

  return allFields.map(f => ({
    ...f,
    // add flag to admin only fields
    isAdminOnly: adminIds.includes(f.internalId)
  }));
};
