import React from 'react';
import { connect } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core';
import { ThemeProvider as SCThemeProvider } from 'styled-components';

function ThemeProvider({ theme, children }) {
  return (
    <SCThemeProvider theme={theme}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </SCThemeProvider>
  );
}

const mapStateToProps = (state, { theme }) => ({
  theme: state.content.themes[theme]
});

export default connect(mapStateToProps)(ThemeProvider);
