import dotProp from 'dot-prop-immutable';

const OPEN_LIGHTBOX = 'state/lightboxes/OPEN_LIGHTBOX';
const CLOSE_LIGHTBOX = 'state/lightboxes/CLOSE_LIGHTBOX';

// reducer as default export
const lightboxes = (state = {}, action) => {
  var temp, temp2;
  switch (action.type) {
    case OPEN_LIGHTBOX:
      temp = dotProp.set(state, action.payload, true);
      if (!action.callback) {
        action.callback = function () {};
      }
      temp2 = Object.assign(temp, {callback: action.callback});
      return temp2;
    case CLOSE_LIGHTBOX:
      return dotProp.delete(state, action.payload);
    default:
      return state;
  }
};

export default lightboxes;

// action creators
export const openLightbox = (id, callback) => ({
  type: OPEN_LIGHTBOX,
  payload: id,
  callback: callback
});

export const closeLightbox = id => ({
  type: CLOSE_LIGHTBOX,
  payload: id
});
