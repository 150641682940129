import { useCallback, useEffect, useRef, useState } from 'react';
import { VINCI_SUPPORT_EMAIL } from './utils/Constants';
import getJWT from './utils/getJWT';
import getLicense, { LICENSE_TYPE } from './utils/getLicense';
import { isEmpty } from './utils/isObjecEmpty';
import jwt from 'jsonwebtoken';
import Cookies from 'universal-cookie';

export const APP_LICENSE = {
  OT: 'none',
  AML: 'aml'
};

const GetGlobalProps = () => {
  const cookies = new Cookies();
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [isLoading, setIsLoading] = useState(false);
  const [isOTLoading, setIsOTLoading] = useState(false);
  const [isDocusigning, setIsDocusigning] = useState(false);
  const [licenseType, setLicenseType] = useState(APP_LICENSE.OT);
  const [fullJWT, setFullJWT] = useState(null);

  const loadFullJWTPromise = useCallback(() => {
    return fetchAPI('/auth/v1/fullpayload');
  }, []);

  const initState = useCallback(() => {
    const smallJwt = getJWT();

    const isVinciSupport =
      ((smallJwt || {}).userProfile || {}).email === VINCI_SUPPORT_EMAIL;

    if (smallJwt) {
      setIsOTLoading(true);
      const allPromises = [
        getLicense(LICENSE_TYPE.OMNITRACK_LITE_PRODUCT),
        loadFullJWTPromise()
      ];
      Promise.all(allPromises)
        .then(results => {
          const [licenseResponse, jwtResponse] = results;
          const newFullJWT = jwtResponse?.jwt || '';
          const cleanedJWT = newFullJWT
            .replace(/(Bearer: )/, '')
            .replace(/^prefix\./, '')
            .replace(/.suffix$/, '');
          let newFullJWTObject = {};
          try {
            let atobDecodedString, atobDecodedValue, jwtDecodedValue;
            jwtDecodedValue = jwt.decode(cleanedJWT);
            if (jwtDecodedValue) {
              newFullJWTObject = jwtDecodedValue;
            } else {
              atobDecodedString = atob(
                cleanedJWT.replace(/-/g, '+').replace(/_/g, '/')
              );
              if (atobDecodedString) {
                atobDecodedValue = JSON.parse(atobDecodedString);
              }
              if (atobDecodedValue) {
                newFullJWTObject = atobDecodedValue;
              }
            }
          } catch (e) {
            console.error('error decoding fullJWT');
          }
          setFullJWT(newFullJWTObject);
          if (isEmpty(licenseResponse) || isVinciSupport) {
            return;
          }
          setLicenseType(licenseResponse.value);
        })
        .finally(() => setIsOTLoading(false));
    }
  }, []);

  const initFullJWT = useCallback((tenant, auth) => {
    return new Promise((resolve, reject) => {
      cookies.set(`auth-header-${tenant}`, auth, { path: '/' });
      if (!auth) {
        resolve();
      }
      loadFullJWTPromise().then(jwtResponse => {
        const newFullJWT = jwtResponse?.jwt || '';
        const cleanedJWT = newFullJWT
          .replace(/(Bearer: )/, '')
          .replace(/^prefix\./, '')
          .replace(/.suffix$/, '');
        let newFullJWTObject = {};
        try {
          let atobDecodedString, atobDecodedValue, jwtDecodedValue;
          jwtDecodedValue = jwt.decode(cleanedJWT);
          if (jwtDecodedValue) {
            newFullJWTObject = jwtDecodedValue;
          } else {
            atobDecodedString = atob(
              cleanedJWT.replace(/-/g, '+').replace(/_/g, '/')
            );
            if (atobDecodedString) {
              atobDecodedValue = JSON.parse(atobDecodedString);
            }
            if (atobDecodedValue) {
              newFullJWTObject = atobDecodedValue;
            }
          }
          if (
            !fullJWT ||
            !newFullJWTObject ||
            JSON.stringify(fullJWT) !== JSON.stringify(newFullJWTObject)
          ) {
            setFullJWT(newFullJWTObject);
            resolve();
          }
        } catch (e) {
          console.error('error decoding fullJWT');
          if (!fullJWT) {
            setFullJWT({});
            resolve();
          }
        }
      });
    });
  }, []);

  const deleteJWT = useCallback(tenant => {
    cookies.remove(`auth-header-${tenant}`, { path: '/' });
    setFullJWT(null);
  }, []);

  useEffect(() => {
    // init
    initState();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };

  const globalProps = {
    initFullJWT,
    deleteJWT,
    isMobileView: screenSize <= 500,
    isLoading,
    isOTLoading,
    isDocusigning,
    setIsLoading,
    setIsOTLoading,
    setIsDocusigning,
    fullJWT,
    licenseType,
    setLicenseType
  };

  return globalProps;
};

export default GetGlobalProps;
