const getEnv = () => {
	let hostName = window.location.hostname;
	hostName = hostName.toLowerCase();

	if( hostName === 'omnitrack-demo.com'){
		return 'demo';
	}
	else if( hostName === 'omnitrack.vinciworks.com'){
		return 'production';
	}
	else{
		return 'development';
	}
};

export default getEnv;