import Cookies from 'universal-cookie';
import fetchAPI from './fetchAPI';
import jwt from 'jsonwebtoken';
import moment from './momentWithLocale';
import getTenant from './getTenant';

const cookies = new Cookies();

var SessionTimeouts = {
  refreshToken: function () {
    var authHeader, diff, now;
    now = moment.utc();
    if (window.tokenLastRefreshed) {
      diff = now.diff(window.tokenLastRefreshed);
      if (diff < 1000) {
        return;
      }
    }
    window.tokenLastRefreshed = now;
    authHeader = cookies.get(`auth-header-${getTenant()}`);
    if (!authHeader) {
      return;
    }
    fetchAPI('/auth/v1/tokenextend', {
      method: 'POST',
      handleResponse: r =>
        r && r.headers && r.headers.map ? r.headers.map.authorization : ''
    })
      .then(auth => {
        if (!auth) {
          if (window.timeoutLogout) {
            window.timeoutLogout();
          }
          return;
        }
        cookies.set(`auth-header-${getTenant()}`, 'Bearer: ' + auth, {
          path: '/'
        });
      })
      .catch(e => {});
  },
  tenMinuteSessionCheckup(funcs) {
    var authHeader,
      cookieTenant,
      diff,
      jwtToken,
      now,
      sessionExpiryTime,
      sessionExpiryTimestamp,
      sessionExpired;
    if (cookies.get('is-saml')) {
      return;
    }
    if (funcs && funcs.openDialog) {
      window.timeoutOpenDialog = funcs.openDialog;
    }
    if (funcs && funcs.logout) {
      window.timeoutLogout = funcs.logout;
    }
    if (!window.timeoutOpenDialog) {
      window.timeoutOpenDialog = function () {};
    }
    if (!window.timeoutLogout) {
      window.timeoutLogout = function () {};
    }
    if (window.timeoutSinceAPI) {
      clearTimeout(window.timeoutSinceAPI);
    }
    if (window.timeoutSinceTimeoutDialog) {
      clearTimeout(window.timeoutSinceTimeoutDialog);
    }
    authHeader = cookies.get(`auth-header-${getTenant()}`);
    cookieTenant = cookies.get('tenant');
    sessionExpired = true;
    if (authHeader && cookieTenant) {
      jwtToken = authHeader.replace(/(Bearer: )/, '');
      if (jwtToken) {
        this.decoded = jwt.decode(jwtToken);
        if (this.decoded) {
          sessionExpiryTimestamp = this.decoded.exp * 1000;
          sessionExpiryTime = moment.utc(sessionExpiryTimestamp);
          now = moment.utc();
          diff = sessionExpiryTime.diff(now);
          if (diff > 0) {
            sessionExpired = false;
          }
          if (diff <= 600000) {
            window.timeoutOpenDialog();
          }
        }
      }
    }
    if (sessionExpired) {
      window.timeoutLogout();
    }
  }
};

export default SessionTimeouts;
