import React, {
  createContext,
  useEffect,
  useState,
  useReducer,
  useCallback
} from 'react';
import { useHistory } from 'react-router';
import Cookies from 'universal-cookie';
import pagesThatDontRequireLogin from '../../data/pagesThatDontRequireLogin';
import fetchAPI from '../../utils/fetchAPI';
import getJWT from '../../utils/getJWT';
import getSAMLRedirectURL from '../../utils/getSAMLRedirectURL';
import { OmnitrackLoader } from '../Loader';
import PageView from '../view/PageView';

const cookies = new Cookies();

export const PageContext = createContext({
  formInfo: { type: undefined }
});

const reducerInitState = {};

const PageReducer = (state, action) => {
  const { type, payload, index } = action;

  switch (type) {
    case 'update':
      return { ...state, ...payload };
    case 'update_form_nfo':
      return { ...state, formInfo: { ...state.formInfo, ...payload } };

    default:
      return state;
  }
};

const Permissions = {
  SSO: 'sso',
  PUBLIC: 'none',
  STANDARD: 'standard'
};

/**
 * This shall one day replace Page.js
 * @param {*} props
 * @returns
 */
const PageEngine = ({
  page,
  isRedirected,
  setIsRedirected = () => {},
  ...props
}) => {
  const [data, dispatch] = useReducer(PageReducer, reducerInitState);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);

  /**
   * Will return the type of Permissions needed to view this page
   */
  const handleFullPageForm = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const pagePath = window.location.pathname;
        const formId = pagePath.split('/')[4];
        const formTypes = {
          [Permissions.STANDARD]: 'internal',
          [Permissions.SSO]: 'internal',
          [Permissions.PUBLIC]: 'anonymous'
        };

        fetchAPI(`/forms/v1/public/forms/${formId}/info`)
          .then(response => {
            const method = response.method;
            const accessType = formTypes[method];
            // TODO: handle rest of redirections

            dispatch({
              type: 'update_form_nfo',
              payload: { type: accessType }
            });

            /**
             * Note: For now we don't handle redirect of private forms here, we should but it requires
             * better infra and should probably merge Page.js with this file first, then handle all redirections
             * in a single place. Instead we're going to have fetchAPI to handle the redirect (as soon as we'll try to
             * call a private API call with no JWT we'll get redirected)
             */
            resolve(response);
          })
          .catch(reject);
      }),
    []
  );

  const initZendeskWidget = useCallback(() => {
    // const suggestion = props.zendeskSuggestion || 'Omnitrack user guide';
    // setTimeout(() => {
    //   if (window.zE) {
    //     window.zE('webWidget', 'helpCenter:setSuggestions', {
    //       search: suggestion
    //     });
    //   }
    // }, 1000);
    // window.zendeskSuggestion = suggestion;
    // // this should be elsewhere?
    // if (window.zE) {
    //     if (jwtToken && decoded && decoded.privileges.includes('user:read')) {
    //       window.zE('webWidget', 'show');
    //     } else {
    //       window.zE('webWidget', 'hide');
    //     }
    //   }
  }, []);

  useEffect(() => {
    const handleRedirections = async () => {
      const pagePath = window.location.pathname;
      const [, tenantId, pageId, subPath, formId] = pagePath.split('/');
      const isPageFullPageForm =
        pagePath.includes('/forms/assigned') && !pagePath.includes('login');

      let isSSO =
        cookies.get('is-saml') || window.location.href.includes('saml=true');
      let isPublicPage = pagesThatDontRequireLogin.includes(page.id);
      let isExternalUsers = false;

      if (isPageFullPageForm) {
        const { method, external } = await handleFullPageForm();
        isSSO = isSSO || method === Permissions.SSO;
        isPublicPage = isPublicPage || method === Permissions.PUBLIC;
        isExternalUsers = external;
      }

      const jwt = getJWT(); // if null - user isn't logged in
      const isUserLoggedIn = !!jwt;

      if (isSSO && !isExternalUsers && !isUserLoggedIn) {
        // redirect to SSO login
        setIsRedirected(true);
        window.location.href = `https://${
          window.location.host
        }/api/saml/v1/login/${tenantId}?saml=true&redirect=${pagePath.replace(
          `/${tenantId}`,
          ''
        )}`;

        // only true for forms page which is ok for now
      }

      if (!isPublicPage && !isUserLoggedIn) {
        // internal page - user should be logged in - redirect to login page
      }

      // if external page - continue

      setIsLoading(false);
    };
    if (!isRedirected) {
      handleRedirections();
    } else {
      setIsRedirected(false);
    }

    initZendeskWidget();
  }, []);

  const viewProps = { page, ...props };
  const contextProps = { ...data };

  return isLoading ? (
    <OmnitrackLoader isLoading={isLoading} />
  ) : (
    <PageContext.Provider value={contextProps}>
      <PageView {...viewProps} />
    </PageContext.Provider>
  );
};

export default PageEngine;
