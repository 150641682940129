import React from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import isNumber from 'is-number';

// If value is a number, append "px", else return value, else return fallback
const parseValue = (value, fallback) =>
  value ? (isNumber(value) ? `${value}px` : value) : fallback;

const StyledImage = styled.img`
  /* fallback to 100%, or auto if a height is set */
  width: ${({ width, height }) => parseValue(width, height ? 'auto' : '100%')};

  /* only use height if there is no width */
  height: ${({ width, height }) =>
    width
      ? height
        ? console.error(`Don't set both height and width on an Image.`) &&
          'auto'
        : 'auto'
      : parseValue(height, 'auto')};

  max-width: 100%;
`;

function missingImage(src) {
  console.error(`Image not found in assets.yml: ${src}`);

  return null;
}

const Image = ({ src, assets, ...props }) =>
  src in assets ? (
    <StyledImage src={assets[src]} alt="" {...props} />
  ) : (
    missingImage(src)
  );

export default connect(state => ({ assets: state.content.assets }))(Image);
