const SET_COLUMNS = 'gridColumns/SET_COLUMNS';

// reducer as default export
const gridColumns = (state = null, action) => {
  switch (action.type) {
    case SET_COLUMNS:
      return action.payload;
    default:
      return state;
  }
};

export default gridColumns;

// action creators
export const setColumns = id => ({
  type: SET_COLUMNS,
  payload: id
});
