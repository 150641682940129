import React from 'react';
import Cookies from 'universal-cookie';
import { Section } from './layouts/ng-layouts/Containers';
import createTree from '../utils/createTree';
import getSAMLRedirectURL from '../utils/getSAMLRedirectURL';
import { preloadLoadables } from '../utils/components';
import { viewed } from '../store/actionCreators';
import config from '../data/config';
// import { push } from 'connected-react-router';
// import store from '../store';
import jwt from 'jsonwebtoken';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withFetchCallbacks } from '../internal-components/FetchCallbacks';
import allPages from '../data/pages';
import { nonAdminPages } from '../data/nonAdminPages';
import getTenant from '../utils/getTenant';

const cookies = new Cookies();

// TODO configure default layout
const defaultLayout = config.defaultLayout || 'NavPage';
preloadLoadables({ type: defaultLayout });

class Page extends React.Component {
  constructor(props) {
    super(props);
    // this.state = { method: '', loadinFormInfo: false };

    // const path = this.props.pathname;

    // const isPublicForm = path && path.indexOf('/forms/public') === 0;
    // const isPrivateForm = path && path.indexOf('/forms/assigned') === 0;
    // if (isPublicForm) {
    //   window.location.replace(
    //     window.location.href.replace('public', 'assigned')
    //   );
    // }

    // if (isPrivateForm) {
    //   this.state = { ...this.state, loadinFormInfo: true };
    // }

    // calculate the page tree before first render. dynamic changes to tree must happen through redux-connected components within the tree.
    let { layout, ...rest } = this.props;
    this.tree = createTree({ type: layout || defaultLayout, ...rest });
  }

  componentDidMount() {
    const { id } = this.props;
    // const context = this;
    viewed(id);

    const path = this.props.pathname;

    let endUserForm =
      path.indexOf('/forms/public') === 0 ||
      path.indexOf('/forms/assigned') === 0;

    if (!nonAdminPages.includes(id) && !endUserForm) {
      allPages.map(preloadLoadables);
    }
    const suggestion = this.props.zendeskSuggestion || 'Omnitrack user guide';

    // low priority
    setTimeout(() => {
      if (window.zE) {
        window.zE('webWidget', 'helpCenter:setSuggestions', {
          search: suggestion
        });
      }
    }, 1000);

    window.zendeskSuggestion = suggestion;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }

  openSnackbar(id, message) {
    const fn =
      (this.props.fetchCallbacks && this.props.fetchCallbacks.openSnackbar) ||
      (() => {});

    fn(id, message);
  }

  render() {
    var decoded,
      jwtToken,
      pagesThatDontRequireLogin,
      pagesThatRequireUserRead,
      pagesThatRequireAdmin;

    const { id: currentPageId, pathname } = this.props;
    const isPublicForm = pathname && pathname.indexOf('/forms/public') === 0;

    pagesThatDontRequireLogin = [
      'email_sent',
      'first_time',
      'home',
      'login',
      'dual-login',
      'need_help',
      'reset_email_sent',
      'samlerror',
      'set_password',
      'test_page',
      'report-builder',
      'no_tenant',
      'dsconnected',
      'dssigned'
    ];
    pagesThatRequireUserRead = ['users'];
    pagesThatRequireAdmin = [
      'settings',
      'settings-sso',
      'api-documentation',
      'api-key'
    ];
    jwtToken = cookies.get(`auth-header-${getTenant()}`) || '';
    jwtToken = jwtToken.replace(/(Bearer: )/, '');
    if (jwtToken) {
      decoded = jwt.decode(jwtToken);
      if (!decoded) {
        decoded = {};
      }
      if (!decoded.privileges) {
        decoded.privileges = [];
      }
    }

    // ======================================

    // if (!jwtToken && this.state.method === 'sso') {
    //   debugger;
    //   // window.location.replace(
    //   //   window.location.href.replace(location.pathname, '/dual-login')
    //   // );
    // }

    // ======================================

    if (window.zE) {
      if (jwtToken && decoded && decoded.privileges.includes('user:read')) {
        window.zE('webWidget', 'show');
      } else {
        window.zE('webWidget', 'hide');
      }
    }

    const isPublic =
      (currentPageId && pagesThatDontRequireLogin.includes(currentPageId)) ||
      isPublicForm ||
      this.props.isPublicAccess;

    if (
      currentPageId === 'login' &&
      window.location.href.indexOf('jwt%3D') !== -1
    ) {
      this.openSnackbar('error', 'login.expired');
    }

    // FIXME: not requiring for bulk-upload during dev
    if (isPublic || jwtToken) {
      if (
        jwtToken &&
        pagesThatRequireUserRead.includes(currentPageId) &&
        !decoded.privileges.includes('user:read')
      ) {
        return <Redirect to={'/forms'} />;
      } else if (
        pagesThatRequireAdmin.includes(currentPageId) &&
        !decoded.privileges.includes('user:write')
      ) {
        return <Redirect to={'/welcome'} />;
      }
      return <Section id={this.props.id}>{this.tree}</Section>;
    } else if (!currentPageId) {
      console.log('currentPgae id!!!');
    } else {
      var hrefWas = '/' + window.location.href.split('/').slice(4).join('/');
      // console.log('hrefWas ', hrefWas);
      if (!hrefWas.includes('login') && !hrefWas.includes('samlerror')) {
        // console.log('hrefWas 1st if');
        if (
          cookies.get('is-saml') ||
          window.location.href.includes('saml=true')
        ) {
          // console.log('hrefWas: ', hrefWas);
          // console.log('redirecting to: ', getSAMLRedirectURL(''));
          window.location.href = getSAMLRedirectURL(hrefWas);
          // return (
          //   <Redirect
          //     to={getSAMLRedirectURL(hrefWas, true)}
          //   />
          //);
          return <></>;
          // return <Redirect to={getSAMLRedirectURL(hrefWas)} />;
        } else {
          // console.log('not saml if');
          return (
            <Redirect to={'/login?redirect=' + encodeURIComponent(hrefWas)} />
          );
        }
      }
      return <></>;
    }
  }
}

const mapStateToProps = state => {
  const pathname = state.router.location.pathname;

  return {
    pathname
  };
};

export default connect(mapStateToProps)(withFetchCallbacks(Page));
