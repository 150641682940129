import React from 'react';
import { Divider, LinearProgress as _LinearProgress } from '@material-ui/core';
import Animation from './Animation';
import styled from 'styled-components/macro';
import useTranslations from '../hooks/useTranslations';
import Colors from '../internal-components/Colors';
import { SimpleFlexContainer } from './layouts/ng-layouts/Containers';
import Image from './Image';

const LinearProgress = styled(_LinearProgress)`
  position: ${({ relative }) => (relative ? 'absolute' : 'fixed')} !important;
  top: 0;
  left: 0;
  width: ${({ relative }) => (relative ? '100%' : '100vw')};
  z-index: 9999999;
`;

const Backdrop = styled.div`
  position: ${({ relative }) => (relative ? 'absolute' : 'fixed')};
  top: 0;
  left: 0;
  width: ${({ relative }) => (relative ? '100%' : '100vw')};
  height: ${({ relative }) => (relative ? '100%' : '100vh')};
  background-color: rgba(
    255,
    255,
    255,
    ${({ $fullOpacity }) => ($fullOpacity ? '1' : '0.6')}
  );
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loader = ({ isLoading = false }) => {
  return (
    <Animation playAnimation={isLoading} unmountOnExit mountOnEnter>
      <Backdrop data-testid="loader">
        <LinearProgress />
      </Backdrop>
    </Animation>
  );
};

const RelativeBorder = styled.div`
  height: 100%;
  position: relative;
  overflow: ${({ relative }) => (relative ? 'hidden' : 'overflow')};
  ${({ $customCSS }) => $customCSS}
  overflow: auto;
`;

export const RelativeLoader = ({ isLoading, children, customCSS }) => {
  return (
    <RelativeBorder relative={isLoading} $customCSS={customCSS}>
      <Animation playAnimation={isLoading} unmountOnExit mountOnEnter>
        <Backdrop relative data-testid="loader">
          <LinearProgress relative />
        </Backdrop>
      </Animation>
      {children}
    </RelativeBorder>
  );
};

const AnimatedSvg = styled.svg`
  width: 10%;
  @keyframes orbit1 {
    0% {
      transform: rotate(225deg) translateX(21px) translateY(21px)
        rotate(-225deg);
    }
    100% {
      transform: rotate(1665deg) translateX(21px) translateY(21px)
        rotate(-1665deg);
    }
  }
  #actor_2 {
    transform: rotate(225deg) translateX(21px) translateY(21px) rotate(-225deg);
    animation: orbit1 5s ease infinite 1s;
  }
  @keyframes orbit2 {
    0% {
      transform: rotate(225deg) translateX(13px) translateY(13px)
        rotate(-225deg);
    }
    80% {
      transform: rotate(1665deg) translateX(13px) translateY(13px)
        rotate(-1665deg);
    }
    100% {
      transform: rotate(1665deg) translateX(13px) translateY(13px)
        rotate(-1665deg);
    }
  }
  #actor_3 {
    transform: rotate(225deg) translateX(13px) translateY(13px) rotate(-225deg);
    animation: orbit2 5s ease infinite 1s;
  }
`;

const Svg = () => {
  return (
    <AnimatedSvg
      id="SVG-Circus-16e9ce86-3a8c-492f-c150-993ac1512d9c"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid meet"
    >
      <polygon
        id="actor_6"
        points="50.00,42.00 49.00,42.06 48.01,42.25 47.06,42.56 46.15,42.99 45.30,43.53 44.52,44.17 43.84,44.90 43.25,45.71 42.76,46.59 42.39,47.53 42.14,48.50 42.02,49.50 42.02,50.50 42.14,51.50 42.39,52.47 42.76,53.41 43.25,54.29 43.84,55.10 44.52,55.83 45.30,56.47 46.15,57.01 47.06,57.44 48.01,57.75 49.00,57.94 50.00,58.00 51.00,57.94 51.99,57.75 52.94,57.44 53.85,57.01 54.70,56.47 55.48,55.83 56.16,55.10 56.75,54.29 57.24,53.41 57.61,52.47 57.86,51.50 57.98,50.50 57.98,49.50 57.86,48.50 57.61,47.53 57.24,46.59 56.75,45.71 56.16,44.90 55.48,44.17 54.70,43.53 53.85,42.99 52.94,42.56 51.99,42.25 51.00,42.06"
        opacity="1"
        fill="#4c84ff"
        fill-opacity="1"
        stroke="#4c84ff"
        stroke-width="0"
        stroke-opacity="1"
        stroke-dasharray=""
      ></polygon>
      <polygon
        id="actor_5"
        points="50.00,31.00 47.62,31.15 45.27,31.60 43.01,32.33 40.85,33.35 38.83,34.63 36.99,36.15 35.36,37.89 33.96,39.82 32.81,41.91 31.93,44.13 31.34,46.44 31.04,48.81 31.04,51.19 31.34,53.56 31.93,55.87 32.81,58.09 33.96,60.18 35.36,62.11 36.99,63.85 38.83,65.37 40.85,66.65 43.01,67.67 45.27,68.40 47.62,68.85 50.00,69.00 52.38,68.85 54.73,68.40 56.99,67.67 59.15,66.65 61.17,65.37 63.01,63.85 64.64,62.11 66.04,60.18 67.19,58.09 68.07,55.87 68.66,53.56 68.96,51.19 68.96,48.81 68.66,46.44 68.07,44.13 67.19,41.91 66.04,39.82 64.64,37.89 63.01,36.15 61.17,34.63 59.15,33.35 56.99,32.33 54.73,31.60 52.38,31.15"
        opacity="1"
        fill="rgba(161,102,62,0)"
        fill-opacity="1"
        stroke="#4c84ff"
        stroke-width="2"
        stroke-opacity="1"
        stroke-dasharray=""
      ></polygon>
      <polygon
        id="actor_4"
        points="50.00,20.00 46.24,20.24 42.54,20.94 38.96,22.11 35.55,23.71 32.37,25.73 29.46,28.13 26.88,30.88 24.67,33.93 22.86,37.23 21.47,40.73 20.53,44.38 20.06,48.12 20.06,51.88 20.53,55.62 21.47,59.27 22.86,62.77 24.67,66.07 26.88,69.12 29.46,71.87 32.37,74.27 35.55,76.29 38.96,77.89 42.54,79.06 46.24,79.76 50.00,80.00 53.76,79.76 57.46,79.06 61.04,77.89 64.45,76.29 67.63,74.27 70.54,71.87 73.12,69.12 75.33,66.07 77.14,62.77 78.53,59.27 79.47,55.62 79.94,51.88 79.94,48.12 79.47,44.38 78.53,40.73 77.14,37.23 75.33,33.93 73.12,30.88 70.54,28.13 67.63,25.73 64.45,23.71 61.04,22.11 57.46,20.94 53.76,20.24"
        opacity="1"
        fill="rgba(161,102,62,0)"
        fill-opacity="1"
        stroke="#4c84ff"
        stroke-width="2"
        stroke-opacity="1"
        stroke-dasharray=""
      ></polygon>
      <circle
        id="actor_3"
        cx="50"
        cy="50"
        r="5"
        opacity="1"
        fill="#4c84ff"
        fill-opacity="1"
        stroke="rgba(166,3,17,1)"
        stroke-width="0"
        stroke-opacity="1"
        stroke-dasharray=""
      ></circle>
      <circle
        id="actor_2"
        cx="50"
        cy="50"
        r="3.5"
        opacity="1"
        fill="#4c84ff"
        fill-opacity="1"
        stroke="rgba(166,3,17,1)"
        stroke-width="0"
        stroke-opacity="1"
        stroke-dasharray=""
      ></circle>
    </AnimatedSvg>
  );
};
export const OmnitrackLoader = ({ isLoading = false }) => {
  return (
    <Animation playAnimation={isLoading} unmountOnExit mountOnEnter>
      <Backdrop>
        <Svg />
      </Backdrop>
    </Animation>
  );
};

const DocusignSvg = () => {
  return (
    <svg
      style={{ alignSelf: 'center' }}
      width={'10%'}
      version="1.1"
      id="L4"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enable-background="new 0 0 0 0"
      // xml:space="preserve"
    >
      <circle fill={Colors.PRIMARY} stroke="none" cx="30" cy="50" r="6">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.1"
        />
      </circle>
      <circle fill={Colors.PRIMARY} stroke="none" cx="50" cy="50" r="6">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.2"
        />
      </circle>
      <circle fill={Colors.PRIMARY} stroke="none" cx="70" cy="50" r="6">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.3"
        />
      </circle>
    </svg>
  );
};

const Title = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 500;
  & > img {
    margin-right: 1rem;
  }
`;

const DocusignDivider = styled(Divider)`
  && {
    margin: 1rem 0;
  }
`;

export const DocusignLoader = ({ isLoading = false }) => {
  const t = useTranslations();

  return (
    <Animation playAnimation={isLoading} unmountOnExit mountOnEnter>
      <Backdrop $fullOpacity>
        <SimpleFlexContainer>
          <Title>
            <Image src="headerlogo.svg" width={'3rem'} /> Omnitrack
          </Title>
          <DocusignDivider />
          <p style={{ fontSize: 20, margin: 0 }}>{t('loader.docusign.text')}</p>
          <DocusignSvg />
        </SimpleFlexContainer>
      </Backdrop>
    </Animation>
  );
};

// export default OmnitrackLoader;
export default Loader;
