import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import { TweenLite, Power0 } from 'gsap';
import Page from '../components/Page';
import { connect, useSelector } from 'react-redux';
import fetchAPI from '../utils/fetchAPI';
import useGlobalVariables from '../hooks/useGlobalVariables';
import PageEngine from '../components/engine/PageEngine';
// import { getPages, getMenu } from '../store/selectors';

const Pages = React.memo(({ location, reduxLocation, locale }) => {
  const pages = useSelector(state => state.content.pages);
  const defaultLocation = `/${pages[0].id}`;
  const path = location.pathname;
  const isPublicForm = path.indexOf('/forms/public') === 0;
  const isPrivateForm = path.indexOf('/forms/assigned') === 0;
  const [isRedirected, setIsRedirected] = useState(false);

  if (isPublicForm) {
    window.location.replace(window.location.href.replace('public', 'assigned'));
  }

  return (
    <Switch location={location}>
      {pages.map(page => (
        <Route
          // exact
          path={'/' + page.id}
          key={page.id}
        >
          {isPrivateForm && !path.includes('login') ? (
            <PageEngine
              nav={{ pages }}
              page={page}
              {...page}
              locale={locale}
              isRedirected={isRedirected}
              setIsRedirected={setIsRedirected}
            />
          ) : (
            <Page nav={{ pages }} page={page} {...page} locale={locale} />
          )}
        </Route>
      ))}
      {/* if we haven't matched a route, redirect to first page */}
      <Route
        key="defaultRedirect"
        render={() =>
          // compare against redux store to avoid redirects during transition
          reduxLocation !== defaultLocation ? (
            <Redirect to={defaultLocation} />
          ) : null
        }
      />
    </Switch>
  );
});

const mapStateToProps = state => {
  return {
    userProfile: state.userProfile,
    locale: state.state.locale,
    reduxLocation: state.router.location.pathname
  };
};

const UserPages = connect(mapStateToProps)(Pages);

const PageTransitionGroup = () => {
  const location = useLocation();

  return (
    <TransitionGroup>
      <Transition
        key={location.pathname.split('/')[1]}
        timeout={{ enter: 200, exit: 500 }}
        appear
        onEnter={node => {
          if (!node) return;
          TweenLite.set(node, { zIndex: 2 });
          TweenLite.fromTo(
            node,
            0.5,
            { autoAlpha: 0 },
            {
              autoAlpha: 1,
              ease: Power0.easeIn
            }
          );
        }}
        onExit={node => {
          if (!node) return;
          TweenLite.set(node, { clearProps: 'all' });
        }}
      >
        <UserPages location={location} />
      </Transition>
    </TransitionGroup>
  );
};

export default PageTransitionGroup;
