const SET_NEWGROUP = 'newGroup/SET_NEWGROUP';

// reducer as default export
const newGroup = (state = null, action) => {
  switch (action.type) {
    case SET_NEWGROUP:
      return action.payload;
    default:
      return state;
  }
};

export default newGroup;

// action creators
export const setNewGroup = id => ({
  type: SET_NEWGROUP,
  payload: id
});
