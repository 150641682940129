import React from 'react';
import createTree from '../../utils/createTree';
import config from '../../data/config';
import { Section } from '../layouts/ng-layouts/Containers';

const defaultLayout = config.defaultLayout || 'NavPage';

const PageView = props => {
  return (
    <Section id={props.page.id}>
      {createTree({ type: props.layout || defaultLayout, ...props })}
    </Section>
  );
};

export default PageView;
