import dotProp from 'dot-prop-immutable';

const OPEN_BANNER = 'OPEN_BANNER';
const CLOSE_BANNER = 'CLOSE_BANNER';

// reducer as default export
const banners = (state = {}, action) => {
  switch (action.type) {
    case OPEN_BANNER:
      return dotProp.set(state, action.id, true);
    case CLOSE_BANNER:
      return dotProp.delete(state, action.id);
    default:
      return state;
  }
};

export default banners;

// action creators
export const openBanner = id => ({
  type: OPEN_BANNER,
  id: id
});

export const closeBanner = id => ({
  type: CLOSE_BANNER,
  id: id
});
