import { applyMiddleware, compose } from 'redux';
// import { createLogger } from 'redux-logger';
import { routerMiddleware } from 'connected-react-router';
import history from '../modules/history';
import thunk from 'redux-thunk';
import configData from '../../data/config';
import autosave from './autosave';

const IdentityMW = () => next => action => next(action);

 // dev only logger
 const devLogger =
   process.env.NODE_ENV.toLowerCase() === 'development' || process.env.REACT_APP_S3_ENV.toLowerCase() === 'development'
     ? require('redux-logger').createLogger({ collapsed: true })
     : IdentityMW;

//const devLogger = require('redux-logger').createLogger({ collapsed: true });

// setups up redux devtools extension if installed in the browser. https://goo.gl/ohJqka
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default composeEnhancers(
  applyMiddleware(
    routerMiddleware(history),
    thunk,
    devLogger,
    autosave
  )
);
