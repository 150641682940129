import fetch from 'cross-fetch';
import queryString from 'query-string';
import { push } from 'connected-react-router';
import {
  openBanner,
  closeBanner,
  submissionStarted,
  submissionComplete
} from '../actionCreators';
import Cookies from 'universal-cookie';
import getTenant from '../../utils/getTenant';

const cookies = new Cookies();

const sleep = milliseconds => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};

export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';

const setPassword = (state = {}, action) => {
  return state;
};

export default setPassword;

export const SET_PASSWORD_REQUEST = 'SET_PASSWORD_REQUEST';
function setPasswordRequest(password) {
  submissionStarted('setPassword');
  return {
    type: SET_PASSWORD_REQUEST,
    password
  };
}

function setPasswordSuccess(password) {
  submissionComplete('setPassword');
  return {
    type: SET_PASSWORD_SUCCESS,
    password,
    receivedAt: Date.now()
  };
}

export const SET_PASSWORD_FAILURE = 'SET_PASSWORD_FAILURE';
function setPasswordFailure(password, e) {
  submissionComplete('setPassword');
  return {
    type: SET_PASSWORD_FAILURE,
    password,
    data: e,
    receivedAt: Date.now()
  };
}

export function callSetPassword(values, url, method = 'POST', sleepTime = 0) {
  closeBanner('message401');
  closeBanner('message500');
  const { newpassword } = values;
  return dispatch => {
    const token = queryString.parse(window.location.search).token;
    dispatch(setPasswordRequest(newpassword));
    var fetchParams = {
      method: method,
      headers: {
        'x-tenant-id': getTenant(),
        'Content-Type': 'application/json'
      }
    };
    var queryParams = {
      newPassword: newpassword,
      token: token
    };
    if (method === 'POST') {
      fetchParams.body = JSON.stringify(queryParams);
    } else {
      function objToQueryString(obj) {
        const keyValuePairs = [];
        for (let i = 0; i < Object.keys(obj).length; i += 1) {
          keyValuePairs.push(
            `${encodeURIComponent(Object.keys(obj)[i])}=${encodeURIComponent(
              Object.values(obj)[i]
            )}`
          );
        }
        return keyValuePairs.join('&');
      }
      url += '?' + objToQueryString(queryParams);
    }
    let resp;
    return fetch(url, fetchParams)
      .then(response => {
        return new Promise(function (resolve) {
          sleep(sleepTime).then(() => resolve(response));
        });
      })
      .then(response => {
        var auth = response.headers.get('Authorization');
        if (response.status !== 200) {
          return response.json();
        }
        cookies.set(`auth-header-${getTenant()}`, auth, { path: '/' });
        dispatch(setPasswordSuccess(newpassword));
        dispatch(push(values.targetURL));
      })
      .then(response => {
        var error =
          response && response.errors && response.errors.length
            ? response.errors[0]
            : '';
        if (error) {
          if (
            error.message ===
            'com.vinciworks.auth.resetPass.passwordAlreadyChanged'
          ) {
            dispatch(setPasswordFailure(newpassword, error));
            openBanner('reuse_error');
          } else {
            openBanner('genericError');
          }
        }
      })
      .catch(e => {
        dispatch(setPasswordFailure(newpassword, e));
        if (!resp || resp.status < 200 || resp.status > 299) {
          if (values.genericError) {
            dispatch(push(values.genericError));
          } else {
            openBanner('genericError');
          }
        }
      });
  };
}
