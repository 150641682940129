import { createStore } from 'redux';
import rootReducer from './modules';
import enhancers from './enhancers';
import configData from '../data/config';

const store = createStore(rootReducer, { }, enhancers);

// HMR in development
if (process.env.NODE_ENV !== 'production') {
  if (window) window.store = store;

  if (module.hot) {
    module.hot.accept('./modules', () => {
      store.replaceReducer(rootReducer);
    });
  }
}

export default store;
