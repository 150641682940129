const getWorldCheckOptions = () => {
  const countriesByCode = {
    ABW: 'Aruba',
    AFG: 'Afghanistan',
    AGO: 'Angola',
    AIA: 'Anguilla',
    ALA: 'Åland Islands',
    ALB: 'Albania',
    AND: 'Andorra',
    ARE: 'United Arab Emirates',
    ARG: 'Argentina',
    ARM: 'Armenia',
    ASM: 'American Samoa',
    ATA: 'Antarctica',
    ATF: 'French Southern Territories',
    ATG: 'Antigua and Barbuda',
    AUS: 'Australia',
    AUT: 'Austria',
    AZE: 'Azerbaijan',
    BDI: 'Burundi',
    BEL: 'Belgium',
    BEN: 'Benin',
    BES: 'Bonaire, Sint Eustatius and Saba',
    BFA: 'Burkina Faso',
    BGD: 'Bangladesh',
    BGR: 'Bulgaria',
    BHR: 'Bahrain',
    BHS: 'Bahamas',
    BIH: 'Bosnia and Herzegovina',
    BLM: 'Saint Barthélemy',
    BLR: 'Belarus',
    BLZ: 'Belize',
    BMU: 'Bermuda',
    BOL: 'Bolivia, Plurinational State of',
    BRA: 'Brazil',
    BRB: 'Barbados',
    BRN: 'Brunei Darussalam',
    BTN: 'Bhutan',
    BVT: 'Bouvet Island',
    BWA: 'Botswana',
    CAF: 'Central African Republic',
    CAN: 'Canada',
    CCK: 'Cocos (Keeling) Islands',
    CHE: 'Switzerland',
    CHL: 'Chile',
    CHN: 'China',
    CIV: "Côte d'Ivoire",
    CMR: 'Cameroon',
    COD: 'Congo, the Democratic Republic of the',
    COG: 'Congo',
    COK: 'Cook Islands',
    COL: 'Colombia',
    COM: 'Comoros',
    CPV: 'Cape Verde',
    CRI: 'Costa Rica',
    CUB: 'Cuba',
    CUW: 'Curaçao',
    CXR: 'Christmas Island',
    CYM: 'Cayman Islands',
    CYP: 'Cyprus',
    CZE: 'Czech Republic',
    DEU: 'Germany',
    DJI: 'Djibouti',
    DMA: 'Dominica',
    DNK: 'Denmark',
    DOM: 'Dominican Republic',
    DZA: 'Algeria',
    ECU: 'Ecuador',
    EGY: 'Egypt',
    ERI: 'Eritrea',
    ESH: 'Western Sahara',
    ESP: 'Spain',
    EST: 'Estonia',
    ETH: 'Ethiopia',
    FIN: 'Finland',
    FJI: 'Fiji',
    FLK: 'Falkland Islands (Malvinas)',
    FRA: 'France',
    FRO: 'Faroe Islands',
    FSM: 'Micronesia, Federated States of',
    GAB: 'Gabon',
    GBR: 'United Kingdom',
    GEO: 'Georgia',
    GGY: 'Guernsey',
    GHA: 'Ghana',
    GIB: 'Gibraltar',
    GIN: 'Guinea',
    GLP: 'Guadeloupe',
    GMB: 'Gambia',
    GNB: 'Guinea-Bissau',
    GNQ: 'Equatorial Guinea',
    GRC: 'Greece',
    GRD: 'Grenada',
    GRL: 'Greenland',
    GTM: 'Guatemala',
    GUF: 'French Guiana',
    GUM: 'Guam',
    GUY: 'Guyana',
    HKG: 'Hong Kong',
    HMD: 'Heard Island and McDonald Mcdonald Islands',
    HND: 'Honduras',
    HRV: 'Croatia',
    HTI: 'Haiti',
    HUN: 'Hungary',
    IDN: 'Indonesia',
    IMN: 'Isle of Man',
    IND: 'India',
    IOT: 'British Indian Ocean Territory',
    IRL: 'Ireland',
    IRN: 'Iran, Islamic Republic of',
    IRQ: 'Iraq',
    ISL: 'Iceland',
    ISR: 'Israel',
    ITA: 'Italy',
    JAM: 'Jamaica',
    JEY: 'Jersey',
    JOR: 'Jordan',
    JPN: 'Japan',
    KAZ: 'Kazakhstan',
    KEN: 'Kenya',
    KGZ: 'Kyrgyzstan',
    KHM: 'Cambodia',
    KIR: 'Kiribati',
    KNA: 'Saint Kitts and Nevis',
    KOR: 'Korea, Republic of',
    KWT: 'Kuwait',
    LAO: "Lao People's Democratic Republic",
    LBN: 'Lebanon',
    LBR: 'Liberia',
    LBY: 'Libya',
    LCA: 'Saint Lucia',
    LIE: 'Liechtenstein',
    LKA: 'Sri Lanka',
    LSO: 'Lesotho',
    LTU: 'Lithuania',
    LUX: 'Luxembourg',
    LVA: 'Latvia',
    MAC: 'Macao',
    MAF: 'Saint Martin (French part)',
    MAR: 'Morocco',
    MCO: 'Monaco',
    MDA: 'Moldova, Republic of',
    MDG: 'Madagascar',
    MDV: 'Maldives',
    MEX: 'Mexico',
    MHL: 'Marshall Islands',
    MKD: 'North Macedonia',
    MLI: 'Mali',
    MLT: 'Malta',
    MMR: 'Myanmar',
    MNE: 'Montenegro',
    MNG: 'Mongolia',
    MNP: 'Northern Mariana Islands',
    MOZ: 'Mozambique',
    MRT: 'Mauritania',
    MSR: 'Montserrat',
    MTQ: 'Martinique',
    MUS: 'Mauritius',
    MWI: 'Malawi',
    MYS: 'Malaysia',
    MYT: 'Mayotte',
    NAM: 'Namibia',
    NCL: 'New Caledonia',
    NER: 'Niger',
    NFK: 'Norfolk Island',
    NGA: 'Nigeria',
    NIC: 'Nicaragua',
    NIU: 'Niue',
    NLD: 'Netherlands',
    NOR: 'Norway',
    NPL: 'Nepal',
    NRU: 'Nauru',
    NZL: 'New Zealand',
    OMN: 'Oman',
    PAK: 'Pakistan',
    PAN: 'Panama',
    PCN: 'Pitcairn',
    PER: 'Peru',
    PHL: 'Philippines',
    PLW: 'Palau',
    PNG: 'Papua New Guinea',
    POL: 'Poland',
    PRI: 'Puerto Rico',
    PRK: "Korea, Democratic People's Republic of",
    PRT: 'Portugal',
    PRY: 'Paraguay',
    PSE: 'Palestine, State of',
    PYF: 'French Polynesia',
    QAT: 'Qatar',
    REU: 'Réunion',
    ROU: 'Romania',
    RUS: 'Russian Federation',
    RWA: 'Rwanda',
    SAU: 'Saudi Arabia',
    SDN: 'Sudan',
    SEN: 'Senegal',
    SGP: 'Singapore',
    SGS: 'South Georgia and the South Sandwich Islands',
    SHN: 'Saint Helena, Ascension and Tristan da Cunha',
    SJM: 'Svalbard and Jan Mayen',
    SLB: 'Solomon Islands',
    SLE: 'Sierra Leone',
    SLV: 'El Salvador',
    SMR: 'San Marino',
    SOM: 'Somalia',
    SPM: 'Saint Pierre and Miquelon',
    SRB: 'Serbia',
    SSD: 'South Sudan',
    STP: 'Sao Tome and Principe',
    SUR: 'Suriname',
    SVK: 'Slovakia',
    SVN: 'Slovenia',
    SWE: 'Sweden',
    SWZ: 'Eswatini',
    SXM: 'Sint Maarten (Dutch part)',
    SYC: 'Seychelles',
    SYR: 'Syrian Arab Republic',
    TCA: 'Turks and Caicos Islands',
    TCD: 'Chad',
    TGO: 'Togo',
    THA: 'Thailand',
    TJK: 'Tajikistan',
    TKL: 'Tokelau',
    TKM: 'Turkmenistan',
    TLS: 'Timor-Leste',
    TON: 'Tonga',
    TTO: 'Trinidad and Tobago',
    TUN: 'Tunisia',
    TUR: 'Turkey',
    TUV: 'Tuvalu',
    TWN: 'Taiwan, Province of China',
    TZA: 'Tanzania, United Republic of',
    UGA: 'Uganda',
    UKR: 'Ukraine',
    UMI: 'United States Minor Outlying Islands',
    URY: 'Uruguay',
    USA: 'United States',
    UZB: 'Uzbekistan',
    VAT: 'Holy See (Vatican City State)',
    VCT: 'Saint Vincent and the Grenadines',
    VEN: 'Venezuela, Bolivarian Republic of',
    VGB: 'Virgin Islands, British',
    VIR: 'Virgin Islands, U.S.',
    VNM: 'Viet Nam',
    VUT: 'Vanuatu',
    WLF: 'Wallis and Futuna',
    WSM: 'Samoa',
    YEM: 'Yemen',
    ZAF: 'South Africa',
    ZMB: 'Zambia',
    ZWE: 'Zimbabwe',
    ZZZ: 'Unknown'
  };

  const nationalitiesByCode = {
    ABW: 'ARUBA',
    AFG: 'AFGHANISTAN',
    AGO: 'ANGOLA',
    AIA: 'ANGUILLA',
    ALA: 'ÅLAND ISLANDS',
    ALB: 'ALBANIA',
    AND: 'ANDORRA',
    ARE: 'UNITED ARAB EMIRATES',
    ARG: 'ARGENTINA',
    ARM: 'ARMENIA',
    ASM: 'AMERICAN SAMOA',
    ATA: 'ANTARCTICA',
    ATF: 'FRENCH SOUTHERN TERRITORIES',
    ATG: 'ANTIGUA AND BARBUDA',
    AUS: 'AUSTRALIA',
    AUT: 'AUSTRIA',
    AZE: 'AZERBAIJAN',
    BDI: 'BURUNDI',
    BEL: 'BELGIUM',
    BEN: 'BENIN',
    BES: 'BONAIRE, SAINT EUSTATIUS AND SABA',
    BFA: 'BURKINA FASO',
    BGD: 'BANGLADESH',
    BGR: 'BULGARIA',
    BHR: 'BAHRAIN',
    BHS: 'BAHAMAS',
    BIH: 'BOSNIA AND HERZEGOVINA',
    BLM: 'SAINT BARTHÉLEMY',
    BLR: 'BELARUS',
    BLZ: 'BELIZE',
    BMU: 'BERMUDA',
    BOL: 'BOLIVIA, PLURINATIONAL STATE OF',
    BRA: 'BRAZIL',
    BRB: 'BARBADOS',
    BRN: 'BRUNEI DARUSSALAM',
    BTN: 'BHUTAN',
    BVT: 'BOUVET ISLAND',
    BWA: 'BOTSWANA',
    CAF: 'CENTRAL AFRICAN REPUBLIC',
    CAN: 'CANADA',
    CCK: 'COCOS (KEELING) ISLANDS',
    CHE: 'SWITZERLAND',
    CHL: 'CHILE',
    CHN: 'CHINA',
    CIV: "CÔTE D'IVOIRE",
    CMR: 'CAMEROON',
    COD: 'CONGO, DEMOCRATIC REPUBLIC OF',
    COG: 'CONGO',
    COK: 'COOK ISLANDS',
    COL: 'COLOMBIA',
    COM: 'COMOROS',
    CPV: 'CAPE VERDE',
    CRI: 'COSTA RICA',
    CUB: 'CUBA',
    CUW: 'CURAÇAO',
    CXR: 'CHRISTMAS ISLAND',
    CYM: 'CAYMAN ISLANDS',
    CYP: 'CYPRUS',
    CZE: 'CZECH REPUBLIC',
    D: 'GERMANY',
    DJI: 'DJIBOUTI',
    DMA: 'DOMINICA',
    DNK: 'DENMARK',
    DOM: 'DOMINICAN REPUBLIC',
    DZA: 'ALGERIA',
    EAC: 'East African Community',
    ECU: 'ECUADOR',
    EGY: 'EGYPT',
    ERI: 'ERITREA',
    ESH: 'WESTERN SAHARA',
    ESP: 'SPAIN',
    EST: 'ESTONIA',
    ETH: 'ETHIOPIA',
    EUE: 'European Union (EU)',
    FIN: 'FINLAND',
    FJI: 'FIJI',
    FLK: 'FALKLAND ISLANDS (MALVINAS)',
    FRA: 'FRANCE',
    FRO: 'FAROE ISLANDS',
    FSM: 'MICRONESIA, FEDERATED STATES OF',
    GAB: 'GABON',
    GBD: 'BRITISH OVERSEAS TERRITORIES CITIZEN',
    GBO: 'BRITISH OVERSEAS CITIZEN',
    GBP: 'BRITISH PROTECTED PERSON',
    GBR: 'UNITED KINGDOM',
    GBS: 'BRITISH SUBJECT',
    GEO: 'GEORGIA',
    GGY: 'GUERNSEY',
    GHA: 'GHANA',
    GIB: 'GIBRALTAR',
    GIN: 'GUINEA',
    GLP: 'GUADELOUPE',
    GMB: 'GAMBIA',
    GNB: 'GUINEA-BISSAU',
    GNQ: 'EQUATORIAL GUINEA',
    GRC: 'GREECE',
    GRD: 'GRENADA',
    GRL: 'GREENLAND',
    GTM: 'GUATEMALA',
    GUF: 'FRENCH GUIANA',
    GUM: 'GUAM',
    GUY: 'GUYANA',
    HKG: 'HONG KONG',
    HMD: 'HEARD AND MCDONALD ISLANDS',
    HND: 'HONDURAS',
    HRV: 'CROATIA',
    HTI: 'HAITI',
    HUN: 'HUNGARY',
    IDN: 'INDONESIA',
    IMN: 'ISLE OF MAN',
    IND: 'INDIA',
    IOT: 'BRITISH INDIAN OCEAN TERRITORY',
    IRL: 'IRELAND',
    IRN: 'IRAN, ISLAMIC REPUBLIC OF',
    IRQ: 'IRAQ',
    ISL: 'ICELAND',
    ISR: 'ISRAEL',
    ITA: 'ITALY',
    JAM: 'JAMAICA',
    JEY: 'JERSEY',
    JOR: 'JORDAN',
    JPN: 'JAPAN',
    KAZ: 'KAZAKHSTAN',
    KEN: 'KENYA',
    KGZ: 'KYRGYZSTAN',
    KHM: 'CAMBODIA',
    KIR: 'KIRIBATI',
    KNA: 'SAINT KITTS AND NEVIS',
    KOR: 'KOREA, REPUBLIC OF',
    KWT: 'KUWAIT',
    LAO: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
    LBN: 'LEBANON',
    LBR: 'LIBERIA',
    LBY: 'LIBYA',
    LCA: 'SAINT LUCIA',
    LIE: 'LIECHTENSTEIN',
    LKA: 'SRI LANKA',
    LSO: 'LESOTHO',
    LTU: 'LITHUANIA',
    LUX: 'LUXEMBOURG',
    LVA: 'LATVIA',
    MAC: 'MACAO',
    MAF: 'SAINT MARTIN (FRENCH PART)',
    MAR: 'MOROCCO',
    MCO: 'MONACO',
    MDA: 'MOLDOVA, REPUBLIC OF',
    MDG: 'MADAGASCAR',
    MDV: 'MALDIVES',
    MEX: 'MEXICO',
    MHL: 'MARSHALL ISLANDS',
    MKD: 'REPUBLIC OF NORTH MACEDONIA',
    MLI: 'MALI',
    MLT: 'MALTA',
    MMR: 'MYANMAR',
    MNE: 'MONTENEGRO',
    MNG: 'MONGOLIA',
    MNP: 'NORTHERN MARIANA ISLANDS',
    MOZ: 'MOZAMBIQUE',
    MRT: 'MAURITANIA',
    MSR: 'MONTSERRAT',
    MTQ: 'MARTINIQUE',
    MUS: 'MAURITIUS',
    MWI: 'MALAWI',
    MYS: 'MALAYSIA',
    MYT: 'MAYOTTE',
    NAM: 'NAMIBIA',
    NCL: 'NEW CALEDONIA',
    NER: 'NIGER',
    NFK: 'NORFOLK ISLAND',
    NGA: 'NIGERIA',
    NIC: 'NICARAGUA',
    NIU: 'NIUE',
    NLD: 'NETHERLANDS',
    NOR: 'NORWAY',
    NPL: 'NEPAL',
    NRU: 'NAURU',
    NTZ: 'NEUTRAL ZONE',
    NZL: 'NEW ZEALAND',
    OMN: 'OMAN',
    PAK: 'PAKISTAN',
    PAN: 'PANAMA',
    PCN: 'PITCAIRN',
    PER: 'PERU',
    PHL: 'PHILIPPINES',
    PLW: 'PALAU',
    PNG: 'PAPUA NEW GUINEA',
    POL: 'POLAND',
    PRI: 'PUERTO RICO',
    PRK: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
    PRT: 'PORTUGAL',
    PRY: 'PARAGUAY',
    PSE: 'PALESTINE, STATE OF',
    PYF: 'FRENCH POLYNESIA',
    QAT: 'QATAR',
    REU: 'RÉUNION',
    RKS: 'REPUBLIC OF KOSOVO',
    ROU: 'ROMANIA',
    RUS: 'RUSSIAN FEDERATION',
    RWA: 'RWANDA',
    SAU: 'SAUDI ARABIA',
    SDN: 'SUDAN',
    SEN: 'SENEGAL',
    SGP: 'SINGAPORE',
    SGS: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS',
    SHN: 'SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA',
    SJM: 'SVALBARD AND JAN MAYEN',
    SLB: 'SOLOMON ISLANDS',
    SLE: 'SIERRA LEONE',
    SLV: 'EL SALVADOR',
    SMR: 'SAN MARINO',
    SOM: 'SOMALIA',
    SPM: 'SAINT PIERRE AND MIQUELON',
    SRB: 'SERBIA',
    SSD: 'SOUTH SUDAN',
    STP: 'SAO TOME AND PRINCIPE',
    SUR: 'SURINAME',
    SVK: 'SLOVAKIA',
    SVN: 'SLOVENIA',
    SWE: 'SWEDEN',
    SWZ: 'THE KINGDOM OF ESWATINI',
    SXM: 'SINT MAARTEN (DUTCH PART)',
    SYC: 'SEYCHELLES',
    SYR: 'SYRIAN ARAB REPUBLIC',
    TCA: 'TURKS AND CAICOS ISLANDS',
    TCD: 'CHAD',
    TGO: 'TOGO',
    THA: 'THAILAND',
    TJK: 'TAJIKISTAN',
    TKL: 'TOKELAU',
    TKM: 'TURKMENISTAN',
    TLS: 'TIMOR-LESTE',
    TON: 'TONGA',
    TTO: 'TRINIDAD AND TOBAGO',
    TUN: 'TUNISIA',
    TUR: 'TURKEY',
    TUV: 'TUVALU',
    TWN: 'TAIWAN, PROVINCE OF CHINA',
    TZA: 'TANZANIA, UNITED REPUBLIC OF',
    UGA: 'UGANDA',
    UKR: 'UKRAINE',
    UMI: 'UNITED STATES MINOR OUTLYING ISLANDS',
    UNA: 'UNITED NATIONS AGENCY',
    UNK: 'RESIDENT OF KOSOVO (ISSUED BY UNITED NATIONS)',
    UNO: 'UNITED NATIONS ORGANIZATION',
    URY: 'URUGUAY',
    USA: 'UNITED STATES',
    UZB: 'UZBEKISTAN',
    VAT: 'HOLY SEE (VATICAN CITY STATE)',
    VCT: 'SAINT VINCENT AND THE GRENADINES',
    VEN: 'VENEZUELA, BOLIVARIAN REPUBLIC OF',
    VGB: 'VIRGIN ISLANDS, BRITISH',
    VIR: 'VIRGIN ISLANDS, U.S.',
    VNM: 'VIET NAM',
    VUT: 'VANUATU',
    WLF: 'WALLIS AND FUTUNA',
    WSM: 'SAMOA',
    XBA: 'AFRICAN DEVELOPMENT BANK (ADB)',
    XCC: 'CARIBBEAN COMMUNITY',
    XCE: 'COUNCIL OF EUROPE',
    XCO: 'COMMON MARKET FOR EASTERN AND SOUTHERN AFRICA (COMESA)',
    XDC: 'SOUTHERN AFRICAN DEVELOPMENT COMMUNITY',
    XEC: 'ECONOMIC COMMUNITY OF WEST AFRICAN STATES (ECOWAS)',
    XES: 'ORGANIZATION OF EASTERN CARIBBEAN STATES (OECS)',
    XIM: 'AFRICAN EXPORT-IMPORT BANK (AFREXIM BANK)',
    XOM: 'SOVEREIGN MILITARY ORDER OF MALTA',
    XPO: 'INTERNATIONAL CRIMINAL POLICE ORGANIZATION (INTERPOL)',
    XXA: 'STATELESS PERSON',
    XXB: 'REFUGEE',
    XXC: 'REFUGEE, OTHER',
    XXX: 'UNSPECIFIED NATIONALITY',
    YEM: 'YEMEN',
    ZAF: 'SOUTH AFRICA',
    ZMB: 'ZAMBIA',
    ZWE: 'ZIMBABWE',
    ZZZ: 'KOSOVO, RESIDENT OF (ISSUED BY UNITED NATIONS)'
  };

  const countries = Object.values(countriesByCode).sort();
  const nationalities = Object.values(nationalitiesByCode).sort();

  const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  };

  const getCountryCodeByName = name => {
    return getKeyByValue(countriesByCode, name);
  };

  return {
    countriesByCode,
    countries,
    getCountryCodeByName,
    nationalities,
    nationalitiesByCode
  };
};

export default getWorldCheckOptions;
