function removeURLParameter(url, parameter) {
  //prefer to use l.search if you have a location/link object
  var urlparts = url.split('?');
  if (urlparts.length >= 2) {
    var prefix = encodeURIComponent(parameter) + '=';
    var pars = urlparts[1].split(/[&;]/g);

    //reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0; ) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
  }
  return url;
}

export default function getSAMLRedirectURL(hrefWas) {
  var base, newURL, splitResults;
  base = process.env.REACT_APP_API_BASE;
  splitResults = window.location.pathname.split('/');
  //console.log('getSAMLRedirectURL hrefWas=',hrefWas);

  if (splitResults.length > 1) {
    newURL = base.replace(/\/api/, '/' + splitResults[1] + hrefWas);
  } else {
    newURL = base.replace(/\/api/, hrefWas);
  }
  // console.log('getSAMLRedirectURL newURL=', newURL);
  //Remove the JWT parameter, to prevent endless loop
  newURL = removeURLParameter(newURL, 'jwt');
  newURL = removeURLParameter(newURL, 'saml');
  // console.log('getSAMLRedirectURL newURL sanitized=', newURL);
  return newURL;
}
