import getThemeValue from '../../utils/getThemeValue';
import styled from 'styled-components/macro';

const getBackground = ({ theme, backgroundColor }) =>
  getThemeValue(theme, backgroundColor) || backgroundColor || null;

export default styled.div`
  && {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    /* perspective: 1000px; */
    background-color: ${props => getBackground(props)};
    /* overflow hidden by default to prevent nested scrollbars */
    overflow: hidden;

    @media print {
      && {
        overflow: initial;
        overflow-x: visible;
        position: static;
        width: 100%;
        max-width: 90vw;
        margin: 0 auto;
      }

      * {
        box-shadow: none !important;
      }
    }
  }
`;
