import dotProp from 'dot-prop-immutable';
import timestamp from '../../../utils/timestamp';

const VIEWED = 'VIEWED';

// reducer as default export
const viewedReducer = (state = {}, action) => {
  switch (action.type) {
    case VIEWED:
      return dotProp.set(state, action.id, timestamp());
    default:
      return state;
  }
};

export default viewedReducer;

// action creator
export const viewed = id => ({
  type: VIEWED,
  id: id
});
