import React from 'react';
import throttle from 'lodash.throttle';
import { withRouter } from 'react-router-dom';
import store from '../store';
import isUserTyping from '../utils/isUserTyping';

const clickLink = (page, direction) => {
  const el = document.querySelector(`#${page} #link-${direction}`);
  if (el) el.click();
};

const clickNext = page => clickLink(page, 'next');
const clickPrev = page => clickLink(page, 'prev');
const clickNextThrottled = throttle(clickNext, 750);
const clickPrevThrottled = throttle(clickPrev, 750);

class KeyboardNavigation extends React.Component {
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeydown.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeydown.bind(this));
  }

  handleKeydown(e) {
    if (isUserTyping(e)) {
      return;
    }

    // TODO check if the user is typing in an input
    // TODO check assumptions about route location and DOM IDs
    const page = this.props.location.pathname.split('/')[1];

    switch (e.key) {
      case 'ArrowLeft':
        clickPrevThrottled(page);
        break;

      case 'ArrowRight':
        clickNextThrottled(page);
        break;

      case 's':
        if (e.altKey) {
          window.share(store.getState());
        }
        break;

      case '1':
        if (e.altKey) {
          window.autoSubmit && window.autoSubmit(1);
        }
        break;

      case '0':
        if (e.altKey) {
          window.autoSubmit && window.autoSubmit(0);
        }
        break;
      default:
        return;
    }
  }

  render() {
    return null;
  }
}

export default withRouter(KeyboardNavigation);
