import apm from '../rum';
import fetch from 'cross-fetch';
import Cookies from 'universal-cookie';
import getTenant from './getTenant';
import getJWT from './getJWT';
import getEnv from './getEnv';
import axios from 'axios';
import SessionTimeouts from './SessionTimeouts';
//import apm from '../rum';
import { push } from 'connected-react-router';
import store from '../store';
import getSAMLRedirectURL from './getSAMLRedirectURL';
import writeLog from './log';

const cookies = new Cookies();

const fetchOrTimeout = (url, { timeout = 30e3, ...options }) => {
  let timedOut;

  return Promise.race([
    fetch(url, options),
    new Promise(resolve => {
      setTimeout(() => {
        timedOut = true;
        resolve();
      }, timeout);
    })
  ]).then(r => {
    if (timedOut) {
      console.error('timeout', url);
      throw new Error('timeout');
    }

    return r;
  });
};

export const getBase = () => {
  const base = process.env.REACT_APP_API_BASE;

  if (typeof base === 'string' && base.indexOf('http') === 0) {
    return base;
  }

  return window.location.origin + '/api';
};

/**
 * Wraps the `cross-fetch` API, automatically adding necessary headers and the base of the server enpoint URL. Returns the fetch `Promise` with a thenable already attached for error handling.
 *
 * **Tenant ID:**  The `x-tenant-id` is taken from the environment variable `REACT_APP_TENANT_ID`, which should be set in the root `.env` file.
 *
 * **Endoint base:**  The base of the endpoint URL is taken from the environment variable `REACT_APP_API_BASE`, which should be set in the root `.env` file. The `route` parameter is concatenated to this base to get the full URL. The slash belongs to the route by convention, e.g. base `"http://192.168.0.165:8888/api"` and route `"/users/v1/bulkupload"`.
 *
 * The URI is encoded, so do not `encodeURI()` the base or route before passing to `fetchAPI()`, unless you want it double encoded (e.g. `"%2520"` versus `"%20"` for `" "`).
 *
 * To change the endpoint for a particular call, e.g. to hit a mock server, add a `base` property to `options`.
 *
 * **`Content-Type`:**  The wrapper sets the `Content-Type` header to `application/json`, unless the body contains `FormData`, in which case the wrapper lets `fetch` handle setting `multipart/form-data; boundary=...` automatically.
 *
 * If the request `body` is not already a string, the wrapper stringifies it as JSON.
 *
 * You may include `{ 'Content-Type': 'my-custom-type' }` in `options.headers` for a custom content type.
 *
 * **Response:**  The wrapper returns the fetch `Promise` with a thenable `handleResponse` already chained for response parsing. This means that in usage, the first `.then()` chained to `fetchAPI()` already receives the parsed JSON, and a `.catch()` can handle error responses.
 *
 * If the server sends an empty response, `handleResponse` returns the value `{}`.
 *
 * If the server sends an error code, `handleResponse` returns a rejected Promise. The rejected value contains `response` and `json` objects, which respectively contain the response details and the parsed JSON of the response body (or `{}` if empty).
 *
 * You may implement your own `handleResponse` to override the default and pass it in the `options` object.
 *
 * **Example usage:**
 *
 * ```js
 *
 *   // GET request
 *   fetchAPI('/userschema')
 *     // do something with OK response
 *     .then(json => console.log(json))
 *     // do something with bad response
 *     .catch(({ response, json }) => console.log(response, json))
 *
 *   // query parameters -- do not encode
 *   fetchAPI(`/usergroup?query=[[{"field": "'user_id'"}]]`)
 *     .then(json => console.log(json))
 *
 *   // POST request
 *   fetchAPI('/bulkupload', {
 *       method: 'POST',
 *       body: formData,
 *     })
 *     .then(json => console.log(json))
 *
 *   // use a local mock server
 *   fetchAPI('/bulk-upload/validate.json', {
 *       base: 'http://localhost:1000/api'
 *       method: 'POST',
 *       body: formData
 *     })
 *     .then(json => console.log(json))
 * ```
 *
 * @param {string} route
 * @param {object} options
 * @param {object} options.headers
 * @param {string} options.base
 * @param {string|object|FormData} options.body
 * @param {function} options.handleResponse
 * @returns {Promise}
 */

export default function fetchAPI(route, options = {}) {
  let {
    headers,
    base,
    body,
    handleResponse,
    handleNetworkError,
    handleServerError,
    handleValidationError,
    onUploadProgress,
    handleLicensingError,
    ...rest
  } = options;

  var sessionJWT = getJWT();
  var applicationEnv = getEnv();

  if (route && route.indexOf('/api/') === 0) {
    alert(
      `Warning, bad api route. Don't include /api in calls to fetchAPI! Route was ` +
        route
    );
  }

  try {
    writeLog('>>Sending fetchAPI apm transaction');
    apm.startTransaction('fetchAPI', 'APICall');
    writeLog(apm.getCurrentTransaction());

    apm.addLabels({ application: window.location.href });
    apm.addLabels({ appEnvironment: applicationEnv });
    //writeLog('Added application env label: ' + applicationEnv);
  } catch (err) {
    writeLog('Could not start apm tarnsaction');
  }

  try {
    apm.addLabels({ tenantID: sessionJWT.tenantid });
    apm.addLabels({ sessionID: sessionJWT.userId + '_' + sessionJWT.exp });
  } catch (err) {
    apm.addLabels({ sessionID: 'noSession' });
  }

  try {
    let requestBody =
      typeof options.body !== 'undefined'
        ? JSON.stringify(options.body)
        : 'none';

    // writeLog('Tagging this transaction with requestRoute:' + route);
    // writeLog('Tagging this transaction with requestBody:' + requestBody);

    apm.addLabels({ requestRoute: route });
    apm.addLabels({ requestBody: requestBody });
  } catch (err) {
    // writeLog('Tagging this transaction with requestRoute:noLabel');
    apm.addLabels({ route: 'noLabel' });
  }

  if (route.indexOf('tokenextend') === -1) {
    SessionTimeouts.refreshToken.call(SessionTimeouts);
  }

  onUploadProgress = onUploadProgress || (e => writeLog(e));

  handleNetworkError =
    handleNetworkError ||
    (e => {
      writeLog('network', { e });
    });

  handleServerError =
    handleServerError ||
    (r => {
      writeLog('server', { r });
    });

  handleValidationError =
    handleValidationError ||
    (r => {
      writeLog('validation', { r });
    });

  handleLicensingError =
    handleLicensingError ||
    (r => {
      writeLog('license', { r });
    });

  if (route.split('?')[0].includes('undefined') && !options.allowUndefined) {
    console.error('API route contains "undefined"');
  }

  // if (route.includes('.js')) {
  //   throw new Error('Endpoint is a JS file');
  // }

  base = base || getBase();

  if (!base) {
    throw new Error('Missing endpoint for API calls.');
  }

  headers = {
    Authorization: cookies.get(`auth-header-${getTenant()}`),
    // Authentication: cookies.get(`auth-header-${getTenant()}`),
    'x-tenant-id': getTenant(),
    ...headers
  };

  if (!headers.Authorization) {
    delete headers.Authorization;
  }

  if (!headers.Authentication) {
    delete headers.Authentication;
  }

  if (!headers['x-tenant-id']) {
    delete headers['x-tenant-id'];
  }

  const sendingFormData = body instanceof FormData;
  // const sendingFormData = body instanceof FormData;

  handleResponse =
    handleResponse ||
    (sendingFormData
      ? r => r.data
      : r => {
          if (!(r && 'text' in r)) {
            return r;
          }

          // fetch resolve value needs to be parsed
          return r.text().then(text => {
            return text
              ? JSON.parse(text)
              : // return {} for empty response body
                {};
          });
        });

  const handleFetch = r => {
    endTransaction();

    if (r instanceof TypeError) {
      handleNetworkError(r);
      return Promise.reject(r);
    }

    if (!r) {
      throw new Error('no response');
    }

    if (r.ok || r.data) {
      return handleResponse(r);
    }

    if (r.status === 401) {
      if (
        !cookies.get(`auth-header-${getTenant()}`) ||
        getJWT().exp * 1000 < Date.now()
      ) {
        var hrefWas = '/' + window.location.href.split('/').slice(4).join('/');
        if (!hrefWas.includes('login')) {
          if (cookies.get('is-saml')) {
            window.location.href = getSAMLRedirectURL(hrefWas);
          } else {
            store.dispatch(
              push('/login?redirect=' + encodeURIComponent(hrefWas))
            );
          }
        }
        return Promise.reject(new Error('401 unauthorized'));
      } else {
        handleServerError(r);
      }
    }

    if (r.status === 422) {
      return (
        r
          .json()
          .then(b => {
            return handleValidationError(b);
          })
          // break the promise chain
          .then(() => Promise.reject(new Error('422 invalid')))
      );
    }

    if (r.status === 404) {
      return (
        r
          .text()
          .then(b => {
            return handleValidationError({ code: '404' });
          })
          // break the promise chain
          .then(() => Promise.reject(new Error('404 not found')))
      );
    }

    if (r.status === 403) {
      return (
        r
          .json()
          .then(b => {
            return handleLicensingError(b);
          })
          // break the promise chain
          .then(() => Promise.reject(new Error('403 forbidden')))
      );
    }

    if (r.status === 500) {
      handleServerError(r);
    }

    return Promise.reject(new Error('bad response'));
  };

  const handleError = e => {
    writeLog({ e });

    if (e instanceof TypeError) {
      handleNetworkError(e);
      return Promise.reject(e);
    }

    // 422 throws an error to prevent chaining -- so dont handle it again as a server error
    if (e.message !== '422 invalid' && e.message !== '403 forbidden') {
      handleServerError(e);
    }

    endTransaction();

    return Promise.reject(new Error('bad response'));
  };

  const handleAxiosResponse = r => {
    endTransaction();

    if (r instanceof TypeError) {
      handleNetworkError(r);
      return Promise.reject(r);
    }

    if (!r) {
      throw new Error('no response');
    }

    writeLog('response', r);

    if (r.ok || r.data) {
      return handleResponse(r);
    }

    if (r.status === 401) {
      if (!cookies.get(`auth-header-${getTenant()}`)) {
        var hrefWas = '/' + window.location.href.split('/').slice(4).join('/');
        if (!hrefWas.includes('login')) {
          if (cookies.get('is-saml')) {
            window.location.href = getSAMLRedirectURL(hrefWas);
          } else {
            store.dispatch(
              push('/login?redirect=' + encodeURIComponent(hrefWas))
            );
          }
        }
      } else {
        handleServerError(r);
      }
    }

    if (r.status === 422) {
      return (
        r
          .json()
          .then(b => {
            return handleValidationError(b);
          })
          // break the promise chain
          .then(() => Promise.reject(new Error('422 invalid')))
      );
    }

    if (r.status === 404) {
      return (
        r
          .json()
          .then(b => {
            return handleValidationError(b);
          })
          // break the promise chain
          .then(() => Promise.reject(new Error('404 not found')))
      );
    }

    if (r.status === 403) {
      return (
        r
          .json()
          .then(b => {
            return handleLicensingError(b);
          })
          // break the promise chain
          .then(() => Promise.reject(new Error('403 forbidden')))
      );
    }

    if (r.status === 500) {
      handleServerError(r);
    }

    return r;
  };

  const handleAxiosError = e => {
    const r = e.response;
    if (r) {
      if (r.status === 401) {
        if (!cookies.get(`auth-header-${getTenant()}`)) {
          var hrefWas =
            '/' + window.location.href.split('/').slice(4).join('/');
          if (!hrefWas.includes('login')) {
            if (cookies.get('is-saml')) {
              window.location.href = getSAMLRedirectURL(hrefWas);
            } else {
              store.dispatch(
                push('/login?redirect=' + encodeURIComponent(hrefWas))
              );
            }
          }
        } else {
          handleServerError(e);
          return Promise.reject(e);
        }
      }

      if (r.status === 422) {
        handleValidationError(r.data);
        return Promise.reject(e);
      }

      if (r.status === 404) {
        handleValidationError(r.data);
        return Promise.reject(e);
      }

      if (r.status === 403) {
        handleLicensingError(r.data);
        return Promise.reject(e);
      }

      if (r.status === 500) {
        handleServerError(e);
        return Promise.reject(e);
      }
    }

    if (e instanceof TypeError) {
      handleNetworkError(e);
      return Promise.reject(e);
    }

    handleServerError(e);
    return Promise.reject(e);
  };

  // set "Content-Type: application/json" unless flag tells us not to
  if (!sendingFormData) {
    headers['Content-Type'] = 'application/json';

    // temp 422 workaround
    if (body && body.metadata) delete body.metadata;

    if (typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    options = {
      headers,
      body,
      ...rest
    };

    return fetchOrTimeout(encodeURI(base + route), options)
      .then(handleFetch)
      .catch(handleError);
  } else {
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        ...headers
      },
      data: body,
      onUploadProgress,
      url: encodeURI(base + route),
      ...rest
    };

    const request = axios
      .request(config)
      .then(handleAxiosResponse)
      .catch(handleAxiosError);

    return request;
  }
}

window.fetchAPI = fetchAPI;

function endTransaction(r) {
  try {
    var currentTransaction = apm.getCurrentTransaction();
    if (apm.getCurrentTransaction()) {
      //Add label to transaction
      currentTransaction.end();
      writeLog('>>Transaction ' + currentTransaction.id + ' ended');
    }
  } catch (err) {
    writeLog('>>Could not get or end current apm transaction:');
    writeLog(err);
  }

  return r;
}
