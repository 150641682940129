const SET_TURNEDOFFSSO = 'turnedOffSSO/SET_TURNEDOFFSSO';

// reducer as default export
const turnedOffSSO = (state = null, action) => {
  switch (action.type) {
    case SET_TURNEDOFFSSO:
      return action.payload;
    default:
      return state;
  }
};

export default turnedOffSSO;

// action creators
export const setTurnedOffSSO = id => ({
  type: SET_TURNEDOFFSSO,
  payload: id
});
