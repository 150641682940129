const SET_SNACKBARMESSAGE = 'snackbarMessage/SET_SNACKBARMESSAGE';

// reducer as default export
const snackbarMessage = (state = null, action) => {
  switch (action.type) {
    case SET_SNACKBARMESSAGE:
      return action.payload;
    default:
      return state;
  }
};

export default snackbarMessage;

// action creators
export const setSnackbarMessage = id => ({
  type: SET_SNACKBARMESSAGE,
  payload: id
});
