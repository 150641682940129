import fetchAPI from '../../utils/fetchAPI';
import dotProp from 'dot-prop';
import throttle from 'lodash.throttle';

import { SET_AUTOSAVE_STATUS } from '../modules/state/autosave';

// store throttled funcitons in a cache so we call the same reference
const throttledCache = {};

/**
 * Returns a throttled function to make autosave PUT calls, with a consistent reference based on config
 */
const getPut =
  (storePath, endpointPath, wait, callback = () => {}) =>
  store => {
    let key = `${storePath}\t${endpointPath}\t${wait}${
      callback.toString() === '() => {}' ? '' : '\t' + new Date()
    }`;

    let fetchCallbacks = window.fetchCallbacks || {};
    let defaultCallbacks = fetchCallbacks.defaultCallbacks || {};

    if (!throttledCache[key]) {
      throttledCache[key] = throttle(
        (method, body) => {
          // set status to saving but don't interrupt the thread
          setTimeout(() => {
            window.requestAnimationFrame(() => {
              store.dispatch({
                type: SET_AUTOSAVE_STATUS,
                id: storePath,
                status: 'autosave.saving'
              });
            });
          }, 40);

          fetchAPI(endpointPath, {
            method,
            body,
            ...defaultCallbacks
          })
            .then(() => {
              store.dispatch({
                type: SET_AUTOSAVE_STATUS,
                id: storePath,
                status: 'autosave.saved'
              });
              callback(true);
            })
            .catch(() => {
              store.dispatch({
                type: SET_AUTOSAVE_STATUS,
                id: storePath,
                status: 'autosave.failed'
              });
              callback(false);
            });
        },
        wait,
        { leading: true, trailing: true }
      );
    }

    // return the throttled function
    return throttledCache[key];
  };

const autosave = store => next => action => {
  const result = next(action);

  if (!action.autosave) {
    return result;
  }

  const {
    storePath,
    endpointPath,
    method = 'PUT',
    wait = 3000,
    callback
  } = action.autosave;

  // set status to unsaved but don't interrupt the thread
  setTimeout(() => {
    window.requestAnimationFrame(() => {
      store.dispatch({
        type: SET_AUTOSAVE_STATUS,
        id: storePath,
        status: 'autosave.unsaved'
      });
    });
  }, 40);

  const put = getPut(storePath, endpointPath, wait, callback)(store);

  put(method, dotProp.get(store.getState(), storePath));

  return result;
};

export default autosave;
