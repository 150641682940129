import React from 'react';
import Lightbox from '../components/Lightbox';
import ThemeProvider from '../components/ThemeProvider';
import { Link } from 'react-router-dom';
import Text from '../components/Text';
import styled from 'styled-components/macro';
import { Button as MuiButton, TextField, Collapse } from '@material-ui/core';
import { openLightbox } from '../store/actionCreators';
import { getPages } from '../store/selectors';

const Button = styled(MuiButton)`
  && {
    text-transform: none;
    padding: 0.5em 1em;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    border-radius: 0;
  }
`;

const Monospace = styled.span`
  font-family: Consolas, monospace;
  margin-right: 1em;

  & span {
    opacity: 0.5;
  }

  & strong {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const highlightMatches = (string, search) => {
  const stack = search.split('').reverse();

  return string.split('').map((c, i) => {
    if (stack.slice(-1)[0] === c) {
      stack.pop();
      return <strong key={c + i}>{c}</strong>;
    }

    return <span key={c + i}>{c}</span>;
  });
};

function secret() {
  const d = new Date();
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return 7 + day + month;
}

class CheatPanel extends React.Component {
  componentDidMount() {
    // console access
    window[`cp${secret()}`] = () => openLightbox('cheat_panel');

    if (process.env.NODE_ENV !== 'production') {
      window.addEventListener('keydown', this.keydownHandler);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.keydownHandler);
  }

  keydownHandler = e => {
    if (e.key === 'p' && e.altKey) {
      this.setState({ search: '' });
      openLightbox('cheat_panel');
      if (this.input) this.input.focus();
    }
  };

  state = { search: '' };

  input = null;

  render() {
    const { search } = this.state;
    return (
      <ThemeProvider theme="light">
        <Lightbox
          id="cheat_panel"
          content={[
            <React.Fragment key="cheat-panel-fragment">
              <form
                onSubmit={e => {
                  e.preventDefault();
                  const firstButton = document.querySelector(
                    'a[data-filter="1"]'
                  );
                  if (firstButton) {
                    firstButton.click();
                  }
                }}
              >
                <TextField
                  inputRef={node => (this.input = node)}
                  placeholder="Start typing an ID"
                  value={search}
                  onInput={e =>
                    this.setState({ search: e.target.value.trim() })
                  }
                  style={{ width: '100%', marginBottom: 4 }}
                  inputProps={{ style: { padding: '8px 12px' } }}
                />
                <input type="submit" hidden />
              </form>
              <div
                style={{
                  maxHeight: '50vh',
                  overflowY: 'scroll'
                }}
              >
                {getPages().map(p => {
                  const regex = new RegExp(
                    '.*' +
                      search
                        .split('')
                        .map(c => c + '.*')
                        .join('')
                  );

                  const isIn = regex.test(p.id);

                  return (
                    <div key={p.id}>
                      <Collapse in={isIn}>
                        <Button
                          component={Link}
                          to={'/' + p.id}
                          disabled={!isIn}
                          data-filter={isIn ? 1 : 0}
                          style={{ padding: '8px 12px', margin: 0 }}
                        >
                          <Monospace>
                            {highlightMatches(p.id, search)}
                          </Monospace>
                          <Text
                            content={`pages.${p.id}.title`}
                            defaultMessage={p.id}
                            style={{ margin: 0 }}
                            component="span"
                          />{' '}
                        </Button>
                      </Collapse>
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          ]}
        />
      </ThemeProvider>
    );
  }
}

export default CheatPanel;
